import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import fb from "../../img/footer/facebook-f.png";
import insta from "../../img/footer/instagram.png";
import link from "../../img/footer/linkedin-in.png";
import tiktok from "../../img/footer/tiktok.png";
import angle from "../../img/footer/angle-up.png";
import "./footer.css";
import { useState } from "react";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Footer(props) {
  const [scrollTop, setScrollTop] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200) {
        setScrollTop(true);
        setShowTopBtn(true);
      } else {
        setScrollTop(false);
        setShowTopBtn(false);
      }
    });
  }, []);
  const bottomToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Container fluid className="footerCopyRight">
        <Container className="md">
          <Row>
            <Col lg={6} md={12} className="footerCopyRightTextAlign">
              <Row>
                <Col lg={12}>
                  {props.t("Footer © 2023 B2PLAY All Rights Reserved")}
                </Col>
                <Col lg={12}>
                  {" "}
                  {props.t("Footer Owned and Managed by : GBA Fintech Kft")}
                </Col>
              </Row>
            </Col>
            <Col
              align="center"
              sm={12}
              className="d-md-block d-sm-block d-xs-block d-lg-none"
            >
              <hr style={{ width: "50%" }} />
            </Col>
            <Col lg={6} md={12} sm={12} className="textalign">
              <Row>
                <Col lg={12} className="font">
                  {props.t(
                    "Footer Tax number : 29170920241 / Registration number : 01 09 381930"
                  )}
                </Col>
                <Col lg={12} className="w-70 font">
                  {props.t("Footer Budapest - Hungary")}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="footerSocialMedia position-relative">
        <Container>
          {showTopBtn && (
            <div className="position-fixed  scrollButton d-none d-lg-block d-xl-block">
              <Button onClick={bottomToTop}>
                <img src={angle} width={20} alt="uparrow" />
              </Button>
            </div>
          )}
          <Row>
            <Col className="textalign">
              <ul className="social-network social-circle">
                <li>
                  <a href="https://www.facebook.com/B2Play-101227748707720" title="facebook">
                    <img src={fb} style={{ width: "9px" }} alt="facebookIcon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/b2playapp/" title="insta">
                    <img src={insta} alt="instaIcon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@b2playapp" title="tiktok">
                    <img src={tiktok} alt="tiktokIcon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/b2play/" title="linkedin">
                    <img src={link} alt="linkedinIcon" />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Footer));
