import React, { useEffect, useState } from "react";
import "./formstab.css";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import gym from "../../../../img/partner/formstab/gym.png";
import gymHov from "../../../../img/partner/formstab/gymHov.png";
import sportvenue from "../../../../img/partner/formstab/sportvenue.png";
import sportvenueHov from "../../../../img/partner/formstab/sportvenueHov.png";
import addstudio from "../../../../img/partner/formstab/addstudio.png";
import addstudioHov from "../../../../img/partner/formstab/addstudioHov.png";
import personaltrainer from "../../../../img/partner/formstab/personaltrainer.png";
import personaltrainerHov from "../../../../img/partner/formstab/personaltrainerHov.png";
import workshop from "../../../../img/partner/formstab/workshop.png";
import workshopHov from "../../../../img/partner/formstab/workshopHov.png";
import SportVenue from "../../../partnerforms/SportVenue";
import Gym from "../../../partnerforms/Gym";
import Studio from "../../../partnerforms/Studio";
import PersonalTrainer from "../../../partnerforms/PersonalTrainer";
import WorkShop from "../../../partnerforms/WorkShop";
import withRouter from "../../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

function FormTab(props) {
  const [key, setKey] = useState("sportvenue");
  const [searchparams] = useSearchParams();

  useEffect(() => {
    if (searchparams.get("form")) {
      setKey(searchparams.get("form"));
    }
  }, []);

  return (
    <div className="form-tab mt-5">
      <Container>
        <Row>
          {/* <Col align="center" className="d-flex flex-column align-items-center" > */}
          <Col align="center">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-5"
              // style={{maxWidth:"67.5rem"}}
              fill
            >
              <Tab
                eventKey="sportvenue"
                title={
                  <>
                    <img
                      src={key === "sportvenue" ? sportvenue : sportvenueHov}
                      width={20}
                      className="me-2"
                      alt="sportvenue"
                    />
                    {props.t("Partner formtab-section Add Sport Venue")}
                  </>
                }
              >
                <SportVenue />
              </Tab>
              <Tab
                eventKey="gym"
                title={
                  <>
                    <img
                      src={key === "gym" ? gymHov : gym}
                      width={20}
                      className="me-2"
                      alt="gym"
                    />
                    {props.t("Partner formtab-section Add Gym")}
                  </>
                }
              >
                <Gym />
              </Tab>
              <Tab
                eventKey="studio"
                title={
                  <>
                    <img
                      src={key === "studio" ? addstudioHov : addstudio}
                      width={20}
                      className="me-2"
                      alt="addstudio"
                    />
                    {props.t("Partner formtab-section Add Studio")}
                  </>
                }
              >
                <Studio />
              </Tab>
              <Tab
                eventKey="trainer"
                title={
                  <>
                    <img
                      src={
                        key === "trainer" ? personaltrainerHov : personaltrainer
                      }
                      width={20}
                      className="me-2"
                      alt="personaltrainer"
                    />
                    {props.t("Partner formtab-section Add Trainer")}
                  </>
                }
              >
                <PersonalTrainer />
              </Tab>
              <Tab
                eventKey="workshop"
                title={
                  <>
                    <img
                      src={key === "workshop" ? workshopHov : workshop}
                      width={20}
                      className="me-2"
                      alt="workshop"
                    />

                    {props.t("Partner formtab-section Add WorkShop/Class")}
                  </>
                }
              >
                <WorkShop />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

FormTab.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(FormTab));
