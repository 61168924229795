import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import "./partnerforms.css";
import { Formik } from "formik";
import { faEuro } from "@fortawesome/free-solid-svg-icons";
import FormikController from "../common/formui/FormikController";
import { Button, Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import Dropzone from "../common/dropzone/Dropzone";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import getCountry from "../../services/getCountry";
import Spinner from "react-bootstrap/Spinner";
import apiClient from "../../services/apiClient";
import FormSuccessMsg from "../common/formSuccessMsg/FormSuccessMsg";
import { countriesList } from "../../data/countriesList";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useCallback } from "react";

const phoneRegex = /^\d{10}$/;

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  //   country: Yup.string().required("Please select country"),
  studioName: Yup.string().required("Studio business name is required"),
  email: Yup.string().required("Email is required").email("Invalid email"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegex, "Invalid phone number"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string().required("Postal code is required"),
  stripeAccountId: Yup.string()
    .required("Stripe account id is required")
    .email("Invalid stripe id"),
  gymName: Yup.string().required("Where your classes is required"),
  aboutTrainer: Yup.string().required("About your place is required"),
  acceptTerms: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
  price: Yup.number()
    .positive("Must be more than 0")
    .integer("Must be more than 0")
    .required("Required"),
    
  profile_pic: Yup.array().min(1, "Place photos is required"),
  currencyType: Yup.string().required("Currency type is required"),
});

function PersonalTrainer(props) {
  const [modalShow, setModalShow] = useState(false);
  const [countries, setCountries] = useState(countriesList);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);

  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(true);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  // const fetchCountries = async () => {
  //   await getCountry
  //     .getCountries()
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setCountries(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error,'Error');
  //     });
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  const handleSubmit = async (values, { resetForm }) => {
    // if (token) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    resetForm({ values: "" });
    setReset(true);
    setLoading(true);
    const res = await apiClient
      .post("register/createTrainerWeb", values, { headers })
      .then((res) => {
        // setRefreshReCaptcha(r => !r);
        setLoading(false);
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          setReset(false);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error");
      });
    // }
  };

  return (
    <div className="tabs-forms text-start">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          country: "Afghanistan",
          studioName: "",
          email: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          stripeAccountId: "",
          aboutTrainer: "",
          acceptTerms: false,
          price: "",
          currencyType:"",
          gymName: "",
          profile_pic: [],
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row align="left">
              <Col md={6} sm={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label First Name")}
                  name="firstName"
                  placeholder={props.t("Form textbox First Name Placeholder")}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Last Name")}
                  name="lastName"
                  placeholder={props.t("Form textbox Last Name Placeholder")}
                />
              </Col>
            </Row>

            <FormikController
              control="select"
              label={props.t("Form label Country")}
              name="country"
              options={countries}
              // countryApi={true}
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Studio Business Name")}
              name="studioName"
              placeholder={props.t(
                "Form textbox Studio Business Name Placeholder"
              )}
            />

            <FormikController
              control="input"
              type="email"
              label={props.t("Form label E-Mail")}
              name="email"
              placeholder={props.t("Form textbox E-Mail Placeholder")}
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Phone-Number")}
              name="phoneNumber"
              placeholder={props.t("Form textbox Phone-Number Placeholder")}
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Address")}
              name="address"
              placeholder={props.t("Form textbox Address Placeholder")}
            />
            <Row className="mb-3">
              <Col lg={4} md={12} sm={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label City")}
                  name="city"
                  placeholder={props.t("Form textbox partner-city Placeholder")}
                />
              </Col>

              <Col lg={4} md={6} xs={6} className="marginTopClass">
                <FormikController
                  control="input"
                  type="text"
                  label=""
                  name="state"
                  placeholder={props.t("Form textbox state Placeholder")}
                  notRequired
                  noLabel={true}
                />
              </Col>
              <Col lg={4} md={6} xs={6} className="marginTopClass">
                <FormikController
                  control="input"
                  type="text"
                  label=""
                  placeholder={props.t("Form textbox Postal code Placeholder")}
                  name="zipCode"
                  notRequired
                  noLabel={true}
                />
              </Col>
            </Row>
            <FormikController
              control="input"
              type="email"
              label={props.t("Form label Stripe Account ID")}
              placeholder={props.t(
                "Form textbox Stripe Account ID Placeholder"
              )}
              name="stripeAccountId"
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Where your Classes")}
              placeholder={props.t(
                "Form textbox Where your Classes Placeholder"
              )}
              name="gymName"
            />

            <FormikController
              control="input"
              style={{ height: "auto" }}
              label={props.t("Form label Please Tell Us About Your Place")}
              placeholder={props.t(
                "Form textbox Please Tell Us About Your Place Placeholder"
              )}
              name="aboutTrainer"
              as={"textarea"}
              rows={2}
            />

            <Row>
              <Col lg={3} md={6} xs={6}>
                <FormikController
                  control="input"
                  type="number"
                  label={props.t("Form label Price Per Entry")}
                  placeholder="0"
                  name="price"
                />
              </Col>
              <Col lg={3} md={6} xs={6}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Currency Type")}
                  placeholder={props.t(
                    "Form textbox Currency-Type Placeholder"
                  )}
                  name="currencyType"
                  className="fw-bold"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Form.Label className="required">
                  {props.t("Form label Photos for Your Place")}
                </Form.Label>
              </Col>
              <Col lg={12}>
                <Dropzone
                  setFieldValue={setFieldValue}
                  placeImgesError={errors.profile_pic}
                  name="profile_pic"
                  fromWorkShop={false}
                  reset={reset}
                />
                <Form.Control.Feedback type="invalid" className="ms-3">
                  {touched.profile_pic && errors.profile_pic}
                </Form.Control.Feedback>
              </Col>
            </Row>
            {/* <Row>
              
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
              container={{
                element: "PersonalTrainer",
              }}
            >
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            </GoogleReCaptchaProvider>
            </Row>
            <Row>
              <Col id="PersonalTrainer" className="mb-3"></Col>
            </Row> */}
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Form.Check
                    type={"checkbox"}
                    name="acceptTerms"
                    value={values.acceptTerms}
                    onChange={(e) =>
                      setFieldValue("acceptTerms", e.target.checked)
                    }
                    onBlur={handleBlur}
                    isValid={touched.acceptTerms && !errors.acceptTerms}
                    isInvalid={touched.acceptTerms && errors.acceptTerms}
                    checked={values.acceptTerms}
                    inline
                  />
                  <span
                    className={`text-white ${
                      values.acceptTerms ? `term-condition` : `term`
                    } `}
                  >
                    {props.t("Form i agree to")}
                  </span>{" "}
                  <a
                    href="#"
                    className={`${
                      values.acceptTerms ? `term-condition` : `term`
                    }`}
                  >
                    {props.t("Form terms & conditions")}
                  </a>
                  <Form.Control.Feedback type="invalid" className="ms-3">
                    {touched.acceptTerms && errors.acceptTerms}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="submit"
                  className="w-100 rounded-pill border-0 p-3 formBtn mb-5"
                >
                  {loading && <Spinner animation="border" className="me-2" />}
                  {props.t("Form partner form submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <FormSuccessMsg show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

PersonalTrainer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PersonalTrainer));
