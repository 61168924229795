import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../img/logo/logo.png";
import appstore from "../../img/navbar/appstore.png";
import googlestore from "../../img/navbar/googleplay.png";
import eng from "../../img/navbar/UnitedKingdom.png";
import hun from "../../img/navbar/Hungary.png";
import fb from "../../img/navbar/facebook-f.png";
import insta from "../../img/navbar/instagram.png";
import linkedin from "../../img/navbar/linkedin-in.png";
import tiktok from "../../img/navbar/tiktok.png";
import mtwitter from "../../img/navbar/mtwitter.png";
import mfacebook from "../../img/navbar/mfacebook-f.png";
import mlinkedin from "../../img/navbar/mlinkedin-in.png";
import mtiktok from "../../img/navbar/mtiktok.png";
import polygon from "../../img/navbar/polygon.png";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import withRouter from "../withRouter";
import i18n from "../../i18n";
import {
  ChevronDown,
  Facebook,
  Instagram,
  Linkedin,
  Tiktok,
} from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faLinkedin,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { HashLink } from "react-router-hash-link";

function Navigation(props) {
  const [selectedLang, setSelectedLang] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  };

  return (
    <div className="position-relative">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="navbar-custom-bgcolor"
      >
        <Container className="px-lg-0">
          <Navbar.Brand as={Link} to="/">
            <img src={logo} width={178} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse>
            <Nav className="d-none d-lg-flex ms-auto position-relative ">
              <Nav.Link href={props.fromPage == "business" ? "https://apps.apple.com/us/app/b2play-services-provider/id1601864267" : "https://apps.apple.com/app/b2play/id1601863913"}>
                <img src={appstore} width={110} alt="appstore" />
              </Nav.Link>
              <Nav.Link href={props.fromPage == "business"? "https://play.google.com/store/apps/details?id=com.genx.b2playbusiness" : "https://play.google.com/store/apps/details?id=com.genx.b2playappuser"}>
                <img src={googlestore} width={110} alt="googlestore" />
              </Nav.Link>

              <NavDropdown
                id="nav-dropdown-dark-example text-white"
                title={
                  selectedLang === "en" ? (
                    <>
                      <img
                        src={eng}
                        width={16}
                        style={{ verticalAlign: "unset" }}
                        className="me-2"
                        alt="eng flag"
                      />
                      <span className="text-white">ENG</span>
                      <span className="fw-bold">
                        <ChevronDown width={25} height={15} fill="white" />
                      </span>
                    </>
                  ) : (
                    <>
                      <img
                        src={hun}
                        width={16}
                        style={{ verticalAlign: "unset" }}
                        className="me-2"
                        alt="hun flag"
                      />
                      <span className="text-white">HUN</span>
                      <ChevronDown
                        className="fw-bold"
                        width={25}
                        height={15}
                        fill="white"
                      />
                    </>
                  )
                }
                menuVariant="light"
                className="langDropDown my-auto"
              >
                <NavDropdown.Item
                  href="#"
                  onClick={() => changeLanguageAction("en")}
                >
                  <div className="languageFlag">
                    <img src={eng} width={16} alt="eng flag" className="me-2" />
                    ENG
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => changeLanguageAction("hu")}
                >
                  <div className="languageFlag">
                    <img src={hun} width={16} alt="hun flag" className="me-2" />
                    HUN
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
              <div className="px-2 my-auto">
                <Button
                  variant="light"
                  className="registerSearchButton"
                  onClick={() => {
                    navigate("/partner");
                  }}
                >
                  {props.t("Register Your Business")}
                </Button>
              </div>
              <div class="nav-link">
                <ul className="navbar-social-network navbar-social-circle">
                  <li>
                    <a href={"https://www.facebook.com/B2Play-101227748707720"} title="facebook">
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        color="#ffca3d"
                        width={"8px"}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/b2playapp/" title="instagram">
                      {/* <Instagram  color="#ffca3d" /> */}
                      {/* <img src={insta} alt="instaIcon" /> */}
                      <FontAwesomeIcon icon={faInstagram} color="#ffca3d" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@b2playapp" title="tiktok">
                      {/* <Tiktok  color="#ffca3d" /> */}
                      {/* <img src={tiktok} alt="tiktokIcon" /> */}
                      <FontAwesomeIcon
                        icon={faTiktok}
                        color="#ffca3d"
                        width={"11px"}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/b2play/" title="linkedin">
                      {/* <Linkedin color="#ffca3d" /> */}
                      {/* <FontAwesomeIcon icon={["fal", "coffee"]} /> */}
                      {/* <img src={linkedin} alt="linkedinIcon" /> */}
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        color="#ffca3d"
                        width={"11px"}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse id="responsive-navbar-nav" className="hiding">
            <Nav
              className="ms-auto position-relative mobileMenu showMobile navbar-collapse collapse"
              style={{ color: "#020C53", fontWeight: 500 }}
            >
              <div className="position-absolute mobileMenuArrowImage">
                <img src={polygon} width={39} alt="polygon" />
              </div>
              <h3 className="py-2 fw-bold">{props.t("Menu")}</h3>
              <Link
                to="/"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
              <div className="py-2" id="useapp">
                {props.t("How to use the app?")}
              </div>
              </Link>
              <Link
                to="/advertise"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div className="py-2" id="advr">
                  {props.t("Advertise")}
                </div>
              </Link>
              <Link
                to="/business"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div className="py-2" id="buss">
                  {props.t("Business")}
                </div>
              </Link>
              <HashLink
                smooth
                to="/#contact"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div className="py-2" id="con">
                  {props.t("Contact")}
                </div>
              </HashLink>
              <hr style={{ borderTop: "2px solid #21319D", opacity: 1 }} />
              <div className="d-flex flex-wrap">
                <Nav.Link className="pe-2" href="https://apps.apple.com/app/b2play/id1601863913">
                  <img src={appstore} width={110} alt="appstore" />
                </Nav.Link>
                <Nav.Link href="https://play.google.com/store/apps/details?id=com.genx.b2playappuser">
                  <img src={googlestore} width={110} alt="googlestore" />
                </Nav.Link>
              </div>
              <hr style={{ borderTop: "2px solid #21319D", opacity: 1 }} />

              <div className="px-2 text-center">
                <Button
                  variant="light"
                  className="registerSearchButton w-100 h-100"
                  onClick={() => {
                    navigate("/partner");
                  }}
                >
                  {props.t("Register Your Business")}
                </Button>
              </div>

              <div className="nav-link">
                <ul className="navbar-social-network navbar-social-circle d-flex justify-content-center mt-1 mb-0 p-0">
                  <li>
                    <a href="https://www.facebook.com/B2Play-101227748707720" title="facebook">
                      <img
                        src={mfacebook}
                        style={{ width: "9px" }}
                        alt="facebookIcon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/B2Playapp" title="mtwitter" alt="twitterIcon">
                      <img src={mtwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@b2playapp" title="tiktok" alt="tiktokIcon">
                      <img src={mtiktok} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/b2play/" title="linkedin" alt="linkedinIcon">
                      <img src={mlinkedin} />
                    </a>
                  </li>
                </ul>
              </div>

              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => changeLanguageAction("en")}
                  variant="light"
                  className="rounded-pill me-1 py-1"
                  style={{
                    backgroundColor: `${
                      selectedLang === "en" ? "rgb(0, 0, 0,0.1)" : "transparent"
                    }`,
                    border: `${selectedLang !== "en" && "none"}`,
                  }}
                >
                  <img src={eng} width={25} alt="eng flag" />
                  <span
                    className="ps-1 fw-bold align-middle"
                    style={{ color: "#020C53" }}
                  >
                    ENG
                  </span>
                </Button>
                <Button
                  onClick={() => changeLanguageAction("hu")}
                  variant="light"
                  className="rounded-pill py-1"
                  style={{
                    backgroundColor: `${
                      selectedLang === "hu" ? "rgb(0, 0, 0,0.1)" : "transparent"
                    }`,
                    border: `${selectedLang !== "hu" && "none"}`,
                  }}
                >
                  <img src={hun} width={25} alt="hun flag" />
                  <span
                    className="ps-1 fw-bold align-middle"
                    style={{ color: "#020C53" }}
                  >
                    HUN
                  </span>
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="position-absolute w-100 searchNavigation d-none d-xl-block d-lg-block d-xxl-block">
        <Container>
          <div className="d-flex w-50 justify-content-between align-items-center h-100">
            <div id="useapp">
              <Link
                to="/"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div id="advr">{props.t("How to use the app?")}</div>
              </Link>
            </div>
            <Link
              to="/advertise"
              className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <div id="advr">{props.t("Advertise")}</div>
            </Link>
            <Link
              to="/business"
              className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <div id="buss">{props.t("Business")}</div>
            </Link>
            <HashLink
              smooth
              to="/#contact"
              className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <div id="con">{props.t("Contact")}</div>
            </HashLink>
            <Link
              to="/account-delete"
              className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <div id="del">{props.t("Delete account")}</div>
            </Link>
          </div>
        </Container>
      </div>
    </div>
  );
}

Navigation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navigation));
