import React from "react";
import Input from "./Input";
import Select from "./Select";
import RadioButton from "./RadioButton";
import CheckBoxes from "./CheckBoxes";
import InputWithIcon from "./InputWithIcon";

function FormikController(props) {
    const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "input-icon":
      return <InputWithIcon {...rest} />
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "checkbox":
      return <CheckBoxes {...rest} />;
    default:
      return null;
  }
}

export default FormikController;
