import React from "react";
import "./stripAccount.css";
import { Button, Col, Container, Row } from "react-bootstrap";
// import video from "../../../img/home/b2PlayVideoSection/Video.png";
import video from "../../../img/home/b2PlayVideoSection/B2play_Service provider_Final.mp4";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function StripAccount(props) {
  return (
    <div className="business-stripaccount">
      <Container className="position-relative">
        <Row>
          <Col align="center">
            <div className="videoWarpper">
              <div 
                className="videoCard"
              >
                  {/* <iframe
                      src={video}
                      frameborder="0"
                      
                      allowfullscreen
                      title="video"
                      className="w-100 height"
                    /> */}
                    <Video loop controls={["PlayPause"]} className="w-100" playsInline>
                      <source src={video} type="video/mp4" />
                    </Video>
                {/* <img src={video} className="w-100" /> */}
              </div>
            </div>
            <div>
              <div className="mt-4">
                <Button className="strip-button rounded-pill fw-bolder" href="https://dashboard.stripe.com/register">
                {props.t("Biz video-section button")}
                </Button>
                <p
                  className="mt-4 text-white border border-white p-2"
                  style={{ width: "140px", fontSize: "13px" }}
                >
                  Powered by <b>stripe</b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(StripAccount));
