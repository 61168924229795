import React from "react";
import "./bannervideo.css";
import Navigation from "../../navbar/Navigation";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import homeIcon from "../../../img/business/bannerVideo/home.png"
import { Link, useLocation } from "react-router-dom";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function BannerVideo(props) {
  const location = useLocation();
  return (
    <div className="business-bannervideo">
      <Navigation fromPage='business' />
      <Container className="mt-lg-5 mt-md-3">
        <Row>
          <Col sm={12} align="center">
            <h1 className="fw-bold text-white">
              B2Play <span style={{ color: "#FFCA3D" }}>{props.t("Biz Page Title")}</span>
            </h1>
            <Breadcrumb className="d-flex justify-content-center">
              <Breadcrumb.Item linkProps={{ to: "/"}} linkAs={Link} className="text-decoration-none"><img src={homeIcon} width={14} className="mx-2 align-baseline" alt="homeicon" /><span>Homepage</span></Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ to: "/business"}} linkAs={Link} className="text-decoration-none" active={`${location.pathname==="/business" ? true : false}`}>
                B2Play
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(BannerVideo));
