import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import threeplayer from "../../../img/business/sportfacility/threeplayer.png";
import "./sportsFacilities.css";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function SportsFacilities(props) {
  return (
    <div className="sportsFacilities">
      <Container>
        <Row className="gy-2">
          <Col
            className="d-flex justify-content-lg-end  justify-content-md-center"
            lg={6}
            md={12}
          >
            <div className="leftImg">
              <img src={threeplayer} alt="three person" className="w-100" />
            </div>
          </Col>
          <Col
            className="d-flex justify-content-lg-start align-items-center justify-content-md-center"
            lg={6}
            md={12}
          >
            <div className="rightContent " style={{ color: "#020C53" }}>
              <p>{props.t("Biz sports-facilities para1")}</p>

              <p>{props.t("Biz sports-facilities para2")}</p>

              <p>{props.t("Biz sports-facilities para3")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(SportsFacilities));
