import React from "react";
import "./youradhere.css";
import { Col, Container, Row } from "react-bootstrap";
import iphone from "../../../img/advertising/youradhere/iPhone.png";
import iPhoneresponsive from "../../../img/advertising/youradhere/iPhoneresponsive.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function YourAdHere(props) {
  return (
    <div className="ad-youradhere">
      <Container>
        <Row className="mt-md-4 mt-0  gy-3">
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 5, order: 1 }}
            className=" d-flex justify-content-lg-end justify-content-md-center"
          >
            <div className="leftImg  d-none d-sm-block">
              <img src={iphone} className="w-100" alt="iphone" />
            </div>
            <div className="leftImg d-block d-md-none">
              <img src={iPhoneresponsive} className="w-100" />
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 7, order: 2 }}
            className="d-flex justify-content-lg-start justify-content-md-center justify-content-sm-start align-items-center ps-lg-5"
          >
            <div className="fw-bold rightContent">
              <p style={{ color: "#FFCA3D" }}>
              {props.t("ADV Your-ad-section para1")}
              </p>

              <p className="text-white">
              {props.t("ADV Your-ad-section para2")}
              </p>

              <p className="text-white">
              {props.t("ADV Your-ad-section para3")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

YourAdHere.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(YourAdHere));
