import React from "react";
import "./adjoinform.css";
import { Col, Container, Row } from "react-bootstrap";
import Forms from "../form/Forms";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function AdJoinForm(props) {
  return (
    <div className="ad-joinform">
      <Container>
        <Row className="mt-3">
          <Col sm={12} align="center">
            <div className="title">
              <h1 className="fw-bold ad-joinform-underline">
                {props.t("ADV join-us-section title")}
              </h1>
              <h6 className="">{props.t("ADV join-us-section sub-title")}</h6>
            </div>
          </Col>
          <Col className="mt-4 d-flex justify-content-center mb-lg-5 mb-3">
            <div className="formsWrappers">
              <Forms />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

AdJoinForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AdJoinForm));
