import React from 'react'
import PartnerBanner from './Banner/PartnerBanner'
import FormsContainer from './Forms/FormsContainer'
import PartnerFooter from './PartnerFooter/PartnerFooter'
import Footer from '../footer/Footer'

const index = () => {
  return (
    <>
      <PartnerBanner />
      <FormsContainer />
      <PartnerFooter />
      <Footer />
    </>
  )
}

export default index
