import React from "react";
import { Form } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";

function Input(props) {
  const { name, label, placeholder,notRequired,noLabel,validationNotShow ,...rest } = props;
  const [field, meta] = useField(name);
  const configTextfiled = {
    ...rest,
    ...field,
  };
  if (meta && meta.touched && meta.error) {
    configTextfiled.isValid = true;
    configTextfiled.isInvalid = meta.error;
  }
  return (
    <>
      <Form.Group className="mb-3">
        {noLabel ? null: <Form.Label className={`${notRequired?``:`required`}`}>{label}</Form.Label>}
        <Form.Control
          name={name}
          placeholder={placeholder}
          {...configTextfiled}
          {...rest}
        />
        {
          validationNotShow ? null :
        
        <ErrorMessage
          name={name}
          className="invalid-feedback"
          component={"div"}
        />
}
       
      </Form.Group>
    </>
  );
}

export default Input;
