import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import magnify from "../../img/bannerCard/magny.png";
import magnifyHov from "../../img/bannerCard/magnyHov.png";
import availability from "../../img/bannerCard/Availability.png";
import availabilityHov from "../../img/bannerCard/AvailabilityHov.png";
import ready from "../../img/bannerCard/Ready.png";
import readyHov from "../../img/bannerCard/ReadyHov.png";
import digital from "../../img/bannerCard/Digital.png";
import digitalHov from "../../img/bannerCard/DigitalHov.png";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./bannerCard.css";
function BannerCard(props) {
  const [magnifyImg, setMagnifyImg] = useState(false);
  const [availabilityImg, setAvailabilityImg] = useState(false);
  const [readyImg, setReadyImg] = useState(false);
  const [digitalImg, setDigitalImg] = useState(false);

  const cardDetails = [
    {
      id: 1,
      image: magnify,
      imgHov: magnifyHov,
      title: props.t("Home Banner Card Simple"),
      desc: props.t("Home Banner Card Simple Desc"),
    },
    {
      id: 2,
      image: availability,
      imgHov: availabilityHov,
      title: props.t("Home Banner Card Availability"),
      desc: props.t("Home Banner Card Availability Desc"),
    },
    {
      id: 3,
      image: ready,
      imgHov: readyHov,
      title: props.t("Home Banner Card Ready"),
      desc: props.t("Home Banner Card Ready Desc"),
    },
    {
      id: 4,
      image: digital,
      imgHov: digitalHov,
      title: props.t("Home Banner Card Digital"),
      desc: props.t("Home Banner Card Digital Desc"),
    },
  ];
  return (
    <div className="bannerCardWrapper ml-sm-5">
      <Container>
        {/* <Row className="d-none d-lg-block d-xl-block d-xxl-block">
          <Col>
            <div className="d-flex width-100 h-100 justify-content-center">
              {cardDetails.map((item,index) => (
                <Card className="bannerCard h-100 me-lg-3 me-0" key={item.id}>
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      <div className="cardimg">
                        <img src={item.image} alt={item.title} />
                      </div>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-center">
                      {item.title}
                    </Card.Subtitle>
                    <Card.Text className="text-center">{item.desc}</Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Col>
        </Row> */}
        <Row className="d-none d-lg-block d-xl-block d-xxl-block">
          <Col>
            <div className="d-flex width-100 h-100 justify-content-center">
              <Card
                className="bannerCard h-100 me-lg-3 me-0"
                onMouseOver={() => setMagnifyImg(true)}
                onMouseOut={() => setMagnifyImg(false)}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    <div className="cardimg">
                      <img
                        src={magnifyImg ? magnifyHov : magnify}
                        alt="magnify"
                      />
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-center">
                    {props.t("Home Banner Card Simple")}
                  </Card.Subtitle>
                  <Card.Text className="text-center">
                    {props.t("Home Banner Card Simple Desc")}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="bannerCard h-100 me-lg-3 me-0"
                onMouseOver={() => setAvailabilityImg(true)}
                  onMouseOut={() => setAvailabilityImg(false)}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                  <div className="cardimg">
                        <img
                          src={availabilityImg ? availabilityHov : availability}
                          alt="availability"
                        />
                      </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-center">
                  {props.t("Home Banner Card Availability")}
                  </Card.Subtitle>
                  <Card.Text className="text-center">
                  {props.t("Home Banner Card Availability Desc")}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="bannerCard h-100 me-lg-3 me-0"
                onMouseOver={() => setReadyImg(true)}
                onMouseOut={() => setReadyImg(false)}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center"  onMouseOver={() => setReadyImg(true)}
              onMouseOut={() => setReadyImg(false)}>
                    <div className="cardimg">
                        <img src={readyImg ? readyHov : ready} alt="magnify" />
                      </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-center">
                  {props.t("Home Banner Card Ready")}
                  </Card.Subtitle>
                  <Card.Text className="text-center">
                  {props.t("Home Banner Card Ready Desc")}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="bannerCard h-100 me-lg-3 me-0"
                onMouseOver={() => setDigitalImg(true)}
                onMouseOut={() => setDigitalImg(false)}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                  <div className="cardimg">
                        <img
                          src={digitalImg ? digitalHov : digital}
                          alt="digital"
                        />
                      </div>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-center">
                  {props.t("Home Banner Card Digital")}
                  </Card.Subtitle>
                  <Card.Text className="text-center">
                  {props.t("Home Banner Card Digital Desc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>

        <div className="d-md-block d-sm-block d-lg-none">
          <Row>
            <Col md={12} sm={12} xs={12}>
              <div className="d-flex width-100 h-100 justify-content-center">
                <Card
                  className="bannerCard me-md-4 me-3"
                  onMouseOver={() => setMagnifyImg(true)}
                  onMouseOut={() => setMagnifyImg(false)}
                >
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      <div className="cardimg">
                        <img
                          src={magnifyImg ? magnifyHov : magnify}
                          alt="magnify"
                        />
                      </div>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-center">
                      <b>{props.t("Home Banner Card Simple")}</b>
                    </Card.Subtitle>
                    <Card.Text className="text-center">
                      {props.t("Home Banner Card Simple Desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card
                  className="bannerCard"
                  onMouseOver={() => setAvailabilityImg(true)}
                  onMouseOut={() => setAvailabilityImg(false)}
                >
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      <div className="cardimg">
                        <img
                          src={availabilityImg ? availabilityHov : availability}
                          alt="availability"
                        />
                      </div>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-center">
                      {props.t("Home Banner Card Availability")}
                    </Card.Subtitle>
                    <Card.Text className="text-center">
                      {props.t("Home Banner Card Availability Desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              sm={12}
              xs={12}
              onMouseOver={() => setReadyImg(true)}
              onMouseOut={() => setReadyImg(false)}
            >
              <div className="d-flex width-100 h-100 justify-content-center">
                <Card className="bannerCard  me-md-4 me-3">
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      <div className="cardimg">
                        <img src={readyImg ? readyHov : ready} alt="magnify" />
                      </div>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-center">
                      {props.t("Home Banner Card Ready")}
                    </Card.Subtitle>
                    <Card.Text className="text-center">
                      {props.t("Home Banner Card Ready Desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card
                  className="bannerCard "
                  onMouseOver={() => setDigitalImg(true)}
                  onMouseOut={() => setDigitalImg(false)}
                >
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-center">
                      <div className="cardimg">
                        <img
                          src={digitalImg ? digitalHov : digital}
                          alt="digital"
                        />
                      </div>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-center">
                      {props.t("Home Banner Card Digital")}
                    </Card.Subtitle>
                    <Card.Text className="text-center">
                      {props.t("Home Banner Card Digital Desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

BannerCard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(BannerCard));
