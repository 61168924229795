import React from 'react'
import Navigation from '../navbar/Navigation'
import Footer from '../footer/Footer'
import AccountDeleteForm from '../AccountDeleteForm/AccountDeleteForm'

function AccountDeletePage() {
 
  return (
    <div>
      <Navigation />
        <AccountDeleteForm />
    <Footer />
    </div>
  )
}

export default AccountDeletePage