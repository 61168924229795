import axios from "axios";

const apiClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let obj = axios.create(defaultOptions);
  obj.interceptors.request.use(function (config) {
    // const token = localStorage.getItem("userToken");
    // if (token) {
    //   config.headers.Authorization = token ? `Bearer ${token}` : "";
    // }
    return config;
  });

  return obj;
};

export default apiClient();
