import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InputWithIcon(props) {
  const { name, label, placeholder, icon, ...rest } = props;
  const [field, meta] = useField(name);
  const configTextfiled = {
    ...rest,
    ...field,
  };
  if (meta && meta.touched && meta.error) {
    configTextfiled.isValid = true;
    configTextfiled.isInvalid = meta.error;
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className="required">{label}</Form.Label>
        <InputGroup>
          <Form.Control
            name={name}
            placeholder={placeholder}
            {...configTextfiled}
            {...rest}
          />
          <div
            style={{
              position: "absolute",
              right: "6%",
              top: "25%",
              zIndex: "11",
            }}
          >
            <FontAwesomeIcon icon={icon} />
          </div>
        </InputGroup>

        <ErrorMessage
          name={name}
          className="invalid-feedback"
          component={"div"}
        />
      </Form.Group>
    </>
  );
}

export default InputWithIcon;
