import React from "react";
import "./audience.css";
import { Col, Container, Row } from "react-bootstrap";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Audience(props) {
  return (
    <div className="ad-audience">
      <Container>
        <Row className="my-3">
          <Col align="center">
            <h3 style={{ color: "#FFCA3D" }}>
              {props.t("ADV Audience line1")}
              <br />
              <span className="text-white">
                {props.t("ADV Audience line2")}
              </span>
            </h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Audience.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Audience));
