import { Field, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

function RadioButton(props) {
  const { name, label, options, ...rest } = props;
  const [field] = useField(name);

  return options.map((option) => {
    return (
      <React.Fragment key={option.key}>
        <Form.Check
          className="text-white mb-2"
          {...field}
          label={option.key}
          value={option.value}
          checked={field.value === option.value}
          // inline
          {...rest}
        />
      </React.Fragment>
    );
  });
}

export default RadioButton;
