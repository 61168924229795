import React from "react";
import "./problem.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import stopwatch from "../../../img/home/problem/Stopwatch.png";
import shop from "../../../img/home/problem/Shop.png";
import language from "../../../img/home/problem/Language.png";
import softwareIssues from "../../../img/home/problem/SoftwareIssues.png";
import communicationissues from "../../../img/home/problem/Communicationissues.png";
import handlingPayments from "../../../img/home/problem/HandlingPayments.png";
import losingClients from "../../../img/home/problem/Losing Clients.png";
import managingBusiness from "../../../img/home/problem/ManagingBusiness.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";


function Problem(props) {
  const endUserCard = [
    {
      id: 1,
      image: stopwatch,
      desc:props.t("Home problem-section User Card para1")
    },
    {
      id: 2,
      image: shop,
      desc: props.t("Home problem-section User Card para2"),
    },
    {
      id: 3,
      image: language,
      desc: props.t("Home problem-section User Card para3")
    },
  ];
  
  const facilityOwnerCard = [
    {
      id: 1,
      image: softwareIssues,
      desc:props.t("Home problem-section Facility Card para1")
    },
    {
      id: 2,
      image: communicationissues,
      desc:props.t("Home problem-section Facility Card para2")
    },
    {
      id: 3,
      image: handlingPayments,
      desc: props.t("Home problem-section Facility Card para3")
    },
    {
      id: 4,
      image: losingClients,
      desc: props.t("Home problem-section Facility Card para4")
    },
    {
      id: 5,
      image: managingBusiness,
      desc: props.t("Home problem-section Facility Card para5")
    },
  ];
  return (
    <div className="problemSection">
      <Container>
        <Row>
          <Col className="text-center text-white titile">
            <p className="problemSection-underline">{props.t("Home problem-section The Problem")}</p>
            {/* <div className="horizontalLine" /> */}
          </Col>
        </Row>
        <Row className="mt-4 gy-1 justify-content-md-center mb-lg-4 mb-1">
          <Col lg={6} md={12} className="endUserCardWrapper ">
            <Card  className="border-0">
              <Card.Header className="text-capitalize">
                {props.t("Home problem-section User Card title")}
              </Card.Header>
              <ListGroup variant="flush">
                {endUserCard.map((item) => (
                  <ListGroup.Item className="d-inline-flex" key={item.id}>
                    <div>
                      <img src={item.image} alt="stopwatch" />
                    </div>
                    <div className="ps-3 my-auto listRightDesc">
                      {item.desc}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
          <Col lg={6} md={12} className="facilityCardWrapper align-self-center" sm-align="center">
            <div className="centerBox">
            <Card className="border-0">
              <Card.Header className="text-capitalize"  style={{backgroundColor:"#776730"}}>
              {props.t("Home problem-section Facility Card title")}
              </Card.Header>
              <ListGroup variant="flush">
                {facilityOwnerCard.map((item) => (
                  <ListGroup.Item className="d-inline-flex" key={item.id}>
                    <div>
                      <img src={item.image} alt="stopwatch" />
                    </div>
                    <div className="ps-3 my-auto listRightDesc">
                      {item.desc}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Problem.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Problem));
