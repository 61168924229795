import React from "react";
import Navigation from "../../navbar/Navigation";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import homeIcon from "../../../img/business/bannerVideo/home.png"
import "./partnerbanner.css";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

function PartnerBanner(props) {
  return (
    <div className="partner-banner">
      <Navigation />
      <Container className="mt-lg-5 mt-md-5">
        <Row>
          <Col sm={12} align="center" className="mt-4">
            <h1 className="fw-bold text-white mt-lg-0  mt-md-0 mt-2 partner-banner-heading">
            {props.t("Partner Page Half-Title1")}<span style={{ color: "#FFCA3D" }}> {props.t("Partner Page Half-Title2")}</span>
            </h1>
          
            <Breadcrumb className="d-flex justify-content-center">
              <Breadcrumb.Item linkProps={{ to: "#" }} linkAs={Link}>
               
                <span>{props.t("Partner breadcum Add your Sports Venue")}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ to: "#" }} linkAs={Link}>
                
                <span>{props.t("Partner breadcum Gym")}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                linkProps={{ to: "#" }}
                linkAs={Link}
              >
                <span>{props.t("Partner breadcum Personal Tranier Profile")}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
            
          </Col>
        </Row>
      </Container>
    </div>
  );
}

PartnerBanner.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PartnerBanner));
