import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikController from "../../../components/common/formui/FormikController";
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";
import { useCallback, useState } from "react";
import getCountry from "../../../services/getCountry";
import Spinner from "react-bootstrap/Spinner";
import apiClient from "../../../services/apiClient";
import check from "../../../img/common/check.png";
import {countriesList} from "../../../data/countriesList";

const validationSchema = Yup.object({
  companyName: Yup.string().required("Company name is required"),
  email: Yup.string().required("Email is required").email("Invalid email"),
  city: Yup.string().required("City is required"),
  message: Yup.string().required("Message is required"),
});

function Forms(props) {
  const [countries, setCountries] = useState(countriesList);
  const [loading, setLoading] = useState(false);
  const initialText = props.t("Form placeholder Send Message");
  const [buttonText, setButtonText] = useState(initialText);
  const [apiCall, setApiCall] = useState(false);

  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(true);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  // const fetchCountries = async () => {
  //   await getCountry
  //     .getCountries()
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setCountries(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "Error");
  //     });
  // };

  const handleSubmit = async (values, { resetForm }) => {
    // if (token) {
      resetForm({ values: "" });
      setButtonText(props.t("Form placeholder Sending Messages"));
      setLoading(true);
      const res = await apiClient
        .post("addJoinUsformWeb", JSON.stringify(values))
        .then((res) => {
          // setRefreshReCaptcha(r => !r);
          setLoading(false);
          if (res.status) {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error, "Error");
        });
      setButtonText(props.t("Form placeholder Successfully Sent"));
      setApiCall(true);
      setTimeout(() => {
        setApiCall(false);
        setButtonText(initialText);
      }, 3000);
    // }
  };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Formik
              initialValues={{
                companyName: "",
                email: "",
                country: "Afghanistan",
                city: "",
                message: "",
              }}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <FormikController
                    control="input"
                    type="text"
                    label={props.t("Form label Company Name")}
                    name="companyName"
                    placeholder={props.t(
                      "Form textbox Company Name Placeholder"
                    )}
                  />
                  <FormikController
                    control="input"
                    type="email"
                    label={props.t("Form label Email")}
                    name="email"
                    placeholder={props.t("Form textbox Email Placeholder")}
                  />
                  <FormikController
                    control="select"
                    label={props.t("Form label Country")}
                    name="country"
                    options={countries}
                    // countryApi={true}
                  />
                  <FormikController
                    control="input"
                    type="text"
                    label={props.t("Form label City")}
                    name="city"
                    placeholder={props.t("Form textbox City Placeholder")}
                  />
                  <FormikController
                    control="input"
                    style={{ height: "auto" }}
                    label={props.t("Form label Message")}
                    placeholder={props.t("Form textbox Message Placeholder")}
                    name="message"
                    as={"textarea"}
                    rows={2}
                  />

                  {/* <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                    container={{
                      element: "google_recaptcha",
                    }}
                  >
                    <GoogleReCaptcha
                      onVerify={onVerify}
                      refreshReCaptcha={refreshReCaptcha}
                    />
                  </GoogleReCaptchaProvider>
                  <Row>
                    <Col id="google_recaptcha" className="mb-3"></Col>
                  </Row> */}

                  {/* <Form.Group className="mb-3"> */}
                  {/* <Form.Check type="checkbox" label="I'm not a robot" /> */}
                  {/* <img src={captcha} width={200} /> */}
                  {/* </Form.Group> */}
                  <div>
                    <Button
                      type="submit"
                      className="w-100 rounded-pill border-0 p-2"
                    >
                      {loading && (
                        <Spinner animation="border" className="me-2" />
                      )}
                      {apiCall && (
                        <img
                          src={check}
                          width={16}
                          alt="success"
                          className="me-2"
                        />
                      )}
                      {buttonText}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Forms.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Forms));
