import React from "react";
import "./mobileapp.css";
import { Col, Container, Row } from "react-bootstrap";
import person from "../../../img/advertising/mobileapp/person.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function MobileApp(props) {
  return (
    <div className="ad-mobileapp">
      <Container>
        <Row className="mt-md-3 mt-0  mb-md-4 mb-0 gy-3">
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 7, order: 1 }}  className="d-flex justify-content-lg-end justify-content-sm-center align-items-lg-center">
            <div className="leftContent">
              <p style={{color:"#FFCA3D"}}>
              {props.t("ADV Mobileapp-section line1")}
              </p>

              <p className="text-white">
              {props.t("ADV Mobileapp-section line2")}
              </p>

              <p className="text-white">
              {props.t("ADV Mobileapp-section line3")}
              </p>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }} align="center">
            <div className="rightImg">
                <img src={person}  className="w-100"/>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

MobileApp.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MobileApp));
