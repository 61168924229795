import React from "react";
import SportsFacilities from "./sportsFacilities/SportsFacilities";
import Stadium from "./stadium/Stadium";
import Solution from "./solution/solution";
import Facts from "./facts/Facts";
import Features from "./features/Features";
import Problem from "./problem/Problem";
import JoinUs from "./joinUs/JoinUs";
import Mind from "./mind/Mind";
import Footer from "../footer/Footer";
import BannerVideo from "./bannerVideo/BannerVideo";
import StripAccount from "./stripAccount/StripAccount";
import BusinessForm from "../../img/business/businessForm/BusinessForm";

function index() {
  return (
    <>
      <BannerVideo />
      <div style={{ backgroundColor: "#020C53" }}>
        <StripAccount />
        <Facts />
      </div>
      <SportsFacilities />
      <div style={{ backgroundColor: "#020C53" }}>
        <Stadium />
        <Problem />
      </div>
      <Solution />
      <Features />
      <JoinUs />
      <BusinessForm />
      <Mind />
      <Footer />
    </>
  );
}

export default index;
