import React from "react";
import "./features.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import iphone from "../../../img/business/features/iPhone.png";
import easy from "../../../img/business/features/easy.png";
import track from "../../../img/business/features/track.png";
import quick from "../../../img/business/features/quick.png";
import helpful from "../../../img/business/features/helpful.png";
import payment from "../../../img/business/features/payment.png";
import designe from "../../../img/business/features/designe.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Features(props) {
  return (
    <div className="business-features">
      <Container>
        <Row className="mt-4 mb-3">
          <Col lg={4} className="d-none d-lg-flex align-items-center justify-content-center">
            <div className="leftPhoneImg">
              <img src={iphone} className="w-100" alt="phone" />
            </div>
          </Col>
          <Col lg={8} sm={12}>
            <Row>
              <Col sm={12} className="mb-2">
                <h1 className="business-features-underline fw-bold">
                  B2Play  {props.t("Biz features-section heading")}
                </h1>
              </Col>
              <Col lg={12} sm={12} className="d-flex justify-content-lg-start justify-content-center">
                <Card className="ml-0">
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <div className="imgdiv">
                      <img src={easy} alt="hand" className="w-100" />
                    </div>
                    <Card.Title className="fw-bold"> {props.t("Biz features-section Easycard")}</Card.Title>
                    <Card.Text>
                    {props.t("Biz features-section Easycard-desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <div className="imgdiv">
                      <img src={track} alt="track" className="w-100" />
                    </div>
                    <Card.Title className="fw-bold">{props.t("Biz features-section Trackcard")}</Card.Title>
                    <Card.Text>{props.t("Biz features-section Trackcard-desc")}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12} sm={12} className="d-flex justify-content-lg-start justify-content-center">
                <Card>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <div className="imgdiv">
                      <img src={quick} alt="quick" className="w-100" />
                    </div>
                    <Card.Title className="fw-bold">{props.t("Biz features-section Quickcard")}</Card.Title>
                    <Card.Text>
                    {props.t("Biz features-section Trackcard-desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <div className="imgdiv">
                      <img src={helpful} alt="helpful" className="w-100" />
                    </div>
                    <Card.Title className="fw-bold">{props.t("Biz features-section Helpfulcard")}</Card.Title>
                    <Card.Text>
                    {props.t("Biz features-section Helpfulcard-desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12} sm={12} className="d-flex justify-content-lg-start justify-content-center">
                <Card>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <div className="imgdiv">
                      <img src={payment} alt="payment" className="w-100" />
                    </div>
                    <Card.Title className="fw-bold">{props.t("Biz features-section Paymentcard")}</Card.Title>
                    <Card.Text>
                      {props.t("Biz features-section Paymentcard-desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <div className="imgdiv">
                      <img src={designe} alt="designe" className="w-100" />
                    </div>
                    <Card.Title className="fw-bold">{props.t("Biz features-section Designedcard")}</Card.Title>
                    <Card.Text>
                    {props.t("Biz features-section Designedcard-desc")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(Features));
