import React from "react";
import "./mind.css";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import withRouter from "../../../components/withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Mind(props) {
  return (
    <div className="business-mind">
      <Container className="mt-4 mb-md-0 mb-4">
        <Row className="gy-4">
          <Col>
            <h1 className="business-mind-underline fw-bold">
              {props.t("Biz mind-section headingline")}
            </h1>
          </Col>
          <Col sm={12} align="center">
            <div style={{ maxWidth: "755px" }}>
              <Accordion alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {props.t("Biz mind-section tab1-title")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {props.t("Biz mind-section tab1-desc")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {props.t("Biz mind-section tab2-title")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {props.t("Biz mind-section tab2-desc")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {props.t("Biz mind-section tab3-title")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {props.t("Biz mind-section tab3-desc")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {props.t("Biz mind-section tab4-title")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="fw-bold">
                      {props.t("Biz mind-section tab4-desc-step1")}
                    </p>
                    <p>{props.t("Biz mind-section tab4-desc-step1-desc")}</p>
                    <p className="fw-bold">
                      {props.t("Biz mind-section tab4-desc-step2")}
                    </p>
                    <p>
                      {props.t("Biz mind-section tab4-desc-step2-desc-para1")}
                    </p>
                    <p>
                      {props.t("Biz mind-section tab4-desc-step2-desc-para2")}
                    </p>
                    <p>
                      {props.t("Biz mind-section tab4-desc-step2-desc-para3")}
                    </p>
                    <p className="fw-bold">
                      {props.t("Biz mind-section tab4-desc-step3")}
                    </p>
                    <p>
                      {props.t("Biz mind-section tab4-desc-step3-desc-para1")}
                    </p>
                    <p>
                      {props.t("Biz mind-section tab4-desc-step3-desc-para2")}
                    </p>
                    <p className="fw-bold">
                      {props.t("Biz mind-section tab4-desc-step4")}
                    </p>
                    <p>{props.t("Biz mind-section tab4-desc-step4-desc")}</p>
                    <p className="fw-bold mt-4">
                      {props.t("Biz mind-section tab4-step5")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Mind.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Mind));
