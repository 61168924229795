import React from "react";
import Navigation from "../navbar/Navigation";
import Audience from "./audience/Audience";
import AdBanner from "./adbanner/AdBanner";
import MobileApp from "./mobileapp/MobileApp";
import YourAdHere from "./youradhere/YourAdHere";
import AdJoinForm from "./adjoinus/AdJoinForm";
import Footer from "../footer/Footer";

function index() {
  return (
    <>
      <AdBanner />
      <div style={{ backgroundColor: "#020C53" }}>
        <Audience />
        <MobileApp />
        <YourAdHere />
      </div>
      <AdJoinForm />
      <Footer />
    </>
  );
}

export default index;
