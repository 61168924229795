import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./businessform.css";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikController from "../../../components/common/formui/FormikController";
import withRouter from "../../../components/withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";
import { useCallback, useState, useEffect } from "react";
import getCountry from "../../../services/getCountry";
import Spinner from "react-bootstrap/Spinner";
import apiClient from "../../../services/apiClient";
import check from "../../../img/common/check.png";
import {countriesList} from "../../../data/countriesList";


const phoneRegex = /^\d{10}$/;

const validationSchema = Yup.object({
  venue: Yup.string().required("Venue name is required"),
  email: Yup.string().required("Email is required").email("Invalid email"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegex, "Invalid phone number"),
  city: Yup.string().required("City is required"),
  venueType: Yup.string().required("Venue type is required"),
});

function BusinessForm(props) {
  const [countries, setCountries] = useState(countriesList);
  const [loading, setLoading] = useState(false);
  const initialText = props.t("Form placeholder Send Message");
  const [buttonText, setButtonText] = useState(initialText);
  const [apiCall, setApiCall] = useState(false);

  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(true);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  const handleSubmit = async (values, { resetForm }) => {
    // if (token) {
      resetForm({ values: "" });
      setButtonText(props.t("Form placeholder Sending Messages"));
      setLoading(true);
      const res = await apiClient
        .post("addBussinessFormWeb", JSON.stringify(values))
        .then((res) => {
          // setRefreshReCaptcha(r => !r);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error, "Error");
        });
      setButtonText(props.t("Form placeholder Successfully Sent"));
      setApiCall(true);
      setTimeout(() => {
        setApiCall(false);
        setButtonText(initialText);
      }, 3000);
    // }
  };

  // const fetchCountries = async () => {
  //   await getCountry
  //     .getCountries()
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setCountries(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  return (
    <div className="business-form">
      <Container>
        <Row className="mb-3">
          <Col sm={12} className="mt-2">
            <h1 className="business-form-underline fw-bold">
              {props.t("Biz form-section headingline1")}
              <br /> {props.t("Biz form-section headingline2")}
            </h1>
            <p className="text-center subtitle">
              {props.t("Biz form-section subheading")}
            </p>
          </Col>
          <Col sm={12} className="d-flex justify-content-center">
            <div className="h-100 pt-3 formsWrappers">
              <Formik
                initialValues={{
                  venue: "",
                  email: "",
                  phoneNumber: "",
                  city: "",
                  venueType: "",
                  country:"Afghanistan",
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <FormikController
                      control="input"
                      type="text"
                      label={props.t("Form label Venue")}
                      name="venue"
                      placeholder={props.t("Form textbox Venue Placeholder")}
                    />

                    <Row>
                      <Form.Group as={Col} md="6" sm="12" className="mb-3">
                        <FormikController
                          control="input"
                          type="text"
                          label={props.t("Form label Email")}
                          name="email"
                          placeholder={props.t(
                            "Form textbox Email Placeholder"
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" sm="12" className="mb-3">
                        <FormikController
                          control="input"
                          type="text"
                          label={props.t("Form label Phone Number")}
                          name="phoneNumber"
                          placeholder={props.t(
                            "Form text Phone Number Placeholder"
                          )}
                        />
                      </Form.Group>
                      <FormikController
                        control="select"
                        label={props.t("Form label Country")}
                        name="country"
                        options={countries}
                        // countryApi={true}
                      />
                    </Row>

                    <FormikController
                      control="input"
                      type="text"
                      label={props.t("Form label City")}
                      name="city"
                      placeholder={props.t("Form textbox City Placeholder")}
                    />

                    <FormikController
                      control="input"
                      type="text"
                      label={props.t("Form label Venue Type")}
                      name="venueType"
                      placeholder={props.t(
                        "Form textbox Venue Type Placeholder"
                      )}
                    />

                    {/* <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY
                      }
                      container={{
                        element: "google_recaptcha",
                      }}
                    >
                      <GoogleReCaptcha
                        onVerify={onVerify}
                        refreshReCaptcha={refreshReCaptcha}
                      />
                    </GoogleReCaptchaProvider>
                    <Row>
                      <Col id="google_recaptcha" className="mb-3"></Col>
                    </Row> */}

                    {/* <Form.Group className="mb-3"> */}
                    {/* <Form.Check type="checkbox" label="I'm not a robot" /> */}
                    {/* <img src={captcha} width={200} /> */}
                    {/* </Form.Group> */}
                    <div>
                      <Button
                        type="submit"
                        className="w-100 rounded-pill border-0 p-2"
                      >
                        {loading && (
                          <Spinner animation="border" className="me-2" />
                        )}
                        {apiCall && (
                          <img
                            src={check}
                            width={16}
                            alt="success"
                            className="me-2"
                          />
                        )}
                        {buttonText}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

BusinessForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(BusinessForm));
