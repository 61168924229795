export const countriesList = [
  {
    id: 1,
    key: "Afghanistan",
    value: "Afghanistan",
  },
  {
    id: 2,
    key: "Aland Islands",
    value: "Aland Islands",
  },
  {
    id: 3,
    key: "Albania",
    value: "Albania",
  },
  {
    id: 4,
    key: "Algeria",
    value: "Algeria",
  },
  {
    id: 5,
    key: "American Samoa",
    value: "American Samoa",
  },
  {
    id: 6,
    key: "Andorra",
    value: "Andorra",
  },
  {
    id: 7,
    key: "Angola",
    value: "Angola",
  },
  {
    id: 8,
    key: "Anguilla",
    value: "Anguilla",
  },
  {
    id: 9,
    key: "Antarctica",
    value: "Antarctica",
  },

  {
    id: 10,
    key: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
  },
  {
    id: 11,
    key: "Argentina",
    value: "Argentina",
  },
  {
    id: 12,
    key: "Armenia",
    value: "Armenia",
  },

  {
    id: 13,
    key: "Aruba",
    value: "Aruba",
  },
  {
    id: 14,
    key: "Asia/Pacific Region",
    value: "Asia/Pacific Region",
  },
  {
    id: 15,
    key: "Australia",
    value: "Australia",
  },
  {
    id: 16,
    key: "Austria",
    value: "Austria",
  },
  {
    id: 17,
    key: "Azerbaijan",
    value: "Azerbaijan",
  },
  {
    id: 18,
    key: "Bahamas",
    value: "Bahamas",
  },
  {
    id: 19,
    key: "Bahrain",
    value: "Bahrain",
  },
  {
    id: 20,
    key: "Bangladesh",
    value: "Bangladesh",
  },
  {
    id: 21,
    key: "Barbados",
    value: "Barbados",
  },
  {
    id: 22,
    key: "Belarus",
    value: "Belarus",
  },
  {
    id: 23,
    key: "Belgium",
    value: "Belgium",
  },
  {
    id: 24,
    key: "Belize",
    value: "Belize",
  },
  {
    id: 25,
    key: "Benin",
    value: "Benin",
  },
  {
    id: 26,
    key: "Bermuda",
    value: "Bermuda",
  },
  {
    id: 27,
    key: "Bhutan",
    value: "Bhutan",
  },
  {
    id: 28,
    key: "Bolivia",
    value: "Bolivia",
  },
  {
    id: 29,
    key: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    id: 30,
    key: "Botswana",
    value: "Botswana",
  },
  {
    id: 31,
    key: "Bouvet Island",
    value: "Bouvet Island",
  },
  {
    id: 32,
    key: "Brazil",
    value: "Brazil",
  },
  {
    id: 33,
    key: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  {
    id: 34,
    key: "Brunei Darussalam",
    value: "Brunei Darussalam",
  },
  {
    id: 35,
    key: "Bulgaria",
    value: "Bulgaria",
  },
  {
    id: 36,
    key: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    id: 37,
    key: "Burundi",
    value: "Burundi",
  },
  {
    id: 38,
    key: "Cambodia",
    value: "Cambodia",
  },
  {
    id: 39,
    key: "Cameroon",
    value: "Cameroon",
  },
  {
    id: 40,
    key: "Canada",
    value: "Canada",
  },
  {
    id: 41,
    key: "Cape Verde",
    value: "Cape Verde",
  },
  {
    id: 42,
    key: "Cayman Islands",
    value: "Cayman Islands",
  },
  {
    id: 43,
    key: "Central African Republic",
    value: "Central African Republic",
  },
  {
    id: 44,
    key: "Chad",
    value: "Chad",
  },
  {
    id: 45,
    key: "Chile",
    value: "Chile",
  },
  {
    id: 46,
    key: "China",
    value: "China",
  },
  {
    id: 47,
    key: "Christmas Island",
    value: "Christmas Island",
  },
  {
    id: 48,
    key: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
  },
  {
    id: 49,
    key: "Colombia",
    value: "Colombia",
  },
  {
    id: 50,
    key: "Comoros",
    value: "Comoros",
  },
  {
    id: 51,
    key: "Congo",
    value: "Congo",
  },
  {
    id: 52,
    key: "Congo (The Democratic Republic of the)",
    value: "Congo (The Democratic Republic of the)",
  },

  {
    id: 53,
    key: "Cook Islands",
    value: "Cook Islands",
  },
  {
    id: 54,
    key: "Costa Rica",
    value: "Costa Rica",
  },
  {
    id: 55,
    key: "Cote d'Ivoire",
    value: "Cote d'Ivoire",
  },

  {
    id: 56,
    key: "Croatia",
    value: "Croatia",
  },
  {
    id: 57,
    key: "Cuba",
    value: "Cuba",
  },
  {
    id: 58,
    key: "Cyprus",
    value: "Cyprus",
  },
  {
    id: 59,
    key: "Czech Republic",
    value: "Czech Republic",
  },
  {
    id: 60,
    key: "Denmark",
    value: "Denmark",
  },
  {
    id: 61,
    key: "Djibouti",
    value: "Djibouti",
  },
  {
    id: 62,
    key: "Dominica",
    value: "Dominica",
  },
  {
    id: 63,
    key: "Dominican Republic",
    value: "Dominican Republic",
  },
  {
    id: 64,
    key: "Ecuador",
    value: "Ecuador",
  },
  {
    id: 65,
    key: "Egypt",
    value: "Egypt",
  },
  {
    id: 66,
    key: "El Salvador",
    value: "El Salvador",
  },
  {
    id: 67,
    key: "Equatorial Guinea",
    value: "Equatorial Guinea",
  },
  {
    id: 68,
    key: "Eritrea",
    value: "Eritrea",
  },
  {
    id: 69,
    key: "Estonia",
    value: "Estonia",
  },
  {
    id: 70,
    key: "Ethiopia",
    value: "Ethiopia",
  },
  {
    id: 71,
    key: "Europe",
    value: "Europe",
  },
  {
    id: 72,
    key: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
  },
  {
    id: 73,
    key: "Faroe Islands",
    value: "Faroe Islands",
  },
  {
    id: 74,
    key: "Fiji",
    value: "Fiji",
  },
  {
    id: 75,
    key: "Finland",
    value: "Finland",
  },
  {
    id: 76,
    key: "France",
    value: "France",
  },
  {
    id: 77,
    key: "French Guiana",
    value: "French Guiana",
  },
  {
    id: 78,
    key: "French Polynesia",
    value: "French Polynesia",
  },
  {
    id: 79,
    key: "French Southern Territories",
    value: "French Southern Territories",
  },
  {
    id: 80,
    key: "Gabon",
    value: "Gabon",
  },
  {
    id: 81,
    key: "Gambia",
    value: "Gambia",
  },
  {
    id: 82,
    key: "Georgia",
    value: "Georgia",
  },
  {
    id: 83,
    key: "Germany",
    value: "Germany",
  },
  {
    id: 84,
    key: "Ghana",
    value: "Ghana",
  },
  {
    id: 85,
    key: "Gibraltar",
    value: "Gibraltar",
  },
  {
    id: 86,
    key: "Greece",
    value: "Greece",
  },
  {
    id: 87,
    key: "Greenland",
    value: "Greenland",
  },
  {
    id: 88,
    key: "Grenada",
    value: "Grenada",
  },
  {
    id: 89,
    key: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    id: 90,
    key: "Guam",
    value: "Guam",
  },
  {
    id: 91,
    key: "Guatemala",
    value: "Guatemala",
  },
  {
    id: 92,
    key: "Guernsey",
    value: "Guernsey",
  },
  {
    id: 93,
    key: "Guinea",
    value: "Guinea",
  },
  {
    id: 94,
    key: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    id: 95,
    key: "Guyana",
    value: "Guyana",
  },
  {
    id: 96,
    key: "Haiti",
    value: "Haiti",
  },
  {
    id: 97,
    key: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
  },
  {
    id: 98,
    key: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
  },
  {
    id: 99,
    key: "Honduras",
    value: "Honduras",
  },

  {
    id: 100,
    key: "Hong Kong",
    value: "Hong Kong",
  },
  {
    id: 101,
    key: "Hungary",
    value: "Hungary",
  },
  {
    id: 102,
    key: "Iceland",
    value: "Iceland",
  },

  {
    id: 103,
    key: "India",
    value: "India",
  },
  {
    id: 104,
    key: "Indonesia",
    value: "Indonesia",
  },
  {
    id: 105,
    key: "Iran (Islamic Republic of)",
    value: "Iran (Islamic Republic of)",
  },
  {
    id: 106,
    key: "Iraq",
    value: "Iraq",
  },
  {
    id: 107,
    key: "Ireland",
    value: "Ireland",
  },
  {
    id: 108,
    key: "Isle of Man",
    value: "Isle of Man",
  },
  {
    id: 109,
    key: "Israel",
    value: "Israel",
  },
  {
    id: 110,
    key: "Italy",
    value: "Italy",
  },
  {
    id: 111,
    key: "Jamaica",
    value: "Jamaica",
  },
  {
    id: 112,
    key: "Japan",
    value: "Japan",
  },
  {
    id: 113,
    key: "Jersey",
    value: "Jersey",
  },
  {
    id: 114,
    key: "Jordan",
    value: "Jordan",
  },
  {
    id: 115,
    key: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    id: 116,
    key: "Kenya",
    value: "Kenya",
  },
  {
    id: 117,
    key: "Kiribati",
    value: "Kiribati",
  },
  {
    id: 118,
    key: "Korea (Democratic People's Republic of)",
    value: "Korea (Democratic People's Republic of)",
  },
  {
    id: 119,
    key: "Korea (Republic of)",
    value: "Korea (Republic of)",
  },
  {
    id: 120,
    key: "Kuwait",
    value: "Kuwait",
  },
  {
    id: 121,
    key: "Kyrgyzstan",
    value: "Kyrgyzstan",
  },
  {
    id: 122,
    key: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  {
    id: 123,
    key: "Latvia",
    value: "Latvia",
  },
  {
    id: 124,
    key: "Lebanon",
    value: "Lebanon",
  },
  {
    id: 125,
    key: "Lesotho",
    value: "Lesotho",
  },
  {
    id: 126,
    key: "Liberia",
    value: "Liberia",
  },
  {
    id: 127,
    key: "Libyan Arab Jamahiriya",
    value: "Libyan Arab Jamahiriya",
  },
  {
    id: 128,
    key: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    id: 129,
    key: "Lithuania",
    value: "Lithuania",
  },
  {
    id: 130,
    key: "Luxembourg",
    value: "Luxembourg",
  },
  {
    id: 131,
    key: "Macao",
    value: "Macao",
  },
  {
    id: 132,
    key: "Macedonia",
    value: "Macedonia",
  },
  {
    id: 133,
    key: "Madagascar",
    value: "Madagascar",
  },
  {
    id: 134,
    key: "Malawi",
    value: "Malawi",
  },
  {
    id: 135,
    key: "Malaysia",
    value: "Malaysia",
  },
  {
    id: 136,
    key: "Maldives",
    value: "Maldives",
  },
  {
    id: 137,
    key: "Mali",
    value: "Mali",
  },
  {
    id: 138,
    key: "Malta",
    value: "Malta",
  },
  {
    id: 139,
    key: "Marshall Islands",
    value: "Marshall Islands",
  },
  {
    id: 140,
    key: "Martinique",
    value: "Martinique",
  },
  {
    id: 141,
    key: "Mauritania",
    value: "Mauritania",
  },
  {
    id: 142,
    key: "Mauritius",
    value: "Mauritius",
  },

  {
    id: 143,
    key: "Mayotte",
    value: "Mayotte",
  },
  {
    id: 144,
    key: "Mexico",
    value: "Mexico",
  },
  {
    id: 145,
    key: "Micronesia (Federated States of)",
    value: "Micronesia (Federated States of)",
  },

  {
    id: 146,
    key: "Moldova (Republic of)",
    value: "Moldova (Republic of)",
  },
  {
    id: 147,
    key: "Monaco",
    value: "Monaco",
  },
  {
    id: 148,
    key: "Mongolia",
    value: "Mongolia",
  },
  {
    id: 149,
    key: "Montenegro",
    value: "Montenegro",
  },
  {
    id: 150,
    key: "Montserrat",
    value: "Montserrat",
  },
  {
    id: 151,
    key: "Morocco",
    value: "Morocco",
  },
  {
    id: 152,
    key: "Mozambique",
    value: "Mozambique",
  },
  {
    id: 153,
    key: "Myanmar",
    value: "Myanmar",
  },
  {
    id: 154,
    key: "Namibia",
    value: "Namibia",
  },
  {
    id: 155,
    key: "Nauru",
    value: "Nauru",
  },
  {
    id: 156,
    key: "Nepal",
    value: "Nepal",
  },
  {
    id: 157,
    key: "Netherlands",
    value: "Netherlands",
  },
  {
    id: 158,
    key: "Netherlands Antilles",
    value: "Netherlands Antilles",
  },
  {
    id: 159,
    key: "New Caledonia",
    value: "New Caledonia",
  },
  {
    id: 160,
    key: "New Zealand",
    value: "New Zealand",
  },
  {
    id: 161,
    key: "Nicaragua",
    value: "Nicaragua",
  },
  {
    id: 162,
    key: "Niger",
    value: "Niger",
  },
  {
    id: 163,
    key: "Nigeria",
    value: "Nigeria",
  },
  {
    id: 164,
    key: "Niue",
    value: "Niue",
  },
  {
    id: 165,
    key: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    id: 166,
    key: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
  },
  {
    id: 167,
    key: "Norway",
    value: "Norway",
  },
  {
    id: 168,
    key: "Oman",
    value: "Oman",
  },
  {
    id: 169,
    key: "Pakistan",
    value: "Pakistan",
  },
  {
    id: 170,
    key: "Palau",
    value: "Palau",
  },
  {
    id: 171,
    key: "Palestinian Territory",
    value: "Palestinian Territory",
  },
  {
    id: 172,
    key: "Panama",
    value: "Panama",
  },
  {
    id: 173,
    key: "Papua New Guinea",
    value: "Papua New Guinea",
  },
  {
    id: 174,
    key: "Paraguay",
    value: "Paraguay",
  },
  {
    id: 175,
    key: "Peru",
    value: "Peru",
  },
  {
    id: 176,
    key: "Philippines",
    value: "Philippines",
  },
  {
    id: 177,
    key: "Pitcairn",
    value: "Pitcairn",
  },
  {
    id: 178,
    key: "Poland",
    value: "Poland",
  },
  {
    id: 179,
    key: "Portugal",
    value: "Portugal",
  },
  {
    id: 180,
    key: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    id: 181,
    key: "Qatar",
    value: "Qatar",
  },
  {
    id: 182,
    key: "Reunion",
    value: "Reunion",
  },
  {
    id: 183,
    key: "Romania",
    value: "Romania",
  },
  {
    id: 184,
    key: "Russian Federation",
    value: "Russian Federation",
  },
  {
    id: 185,
    key: "Rwanda",
    value: "Rwanda",
  },
  {
    id: 186,
    key: "Saint Helena",
    value: "Saint Helena",
  },
  {
    id: 187,
    key: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
  },
  {
    id: 188,
    key: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    id: 189,
    key: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },

  {
    id: 190,
    key: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  {
    id: 191,
    key: "Samoa",
    value: "Samoa",
  },
  {
    id: 192,
    key: "San Marino",
    value: "San Marino",
  },

  {
    id: 193,
    key: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
  },
  {
    id: 194,
    key: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    id: 195,
    key: "Senegal",
    value: "Senegal",
  },
  {
    id: 196,
    key: "Serbia",
    value: "Serbia",
  },
  {
    id: 197,
    key: "Seychelles",
    value: "Seychelles",
  },
  {
    id: 198,
    key: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    id: 199,
    key: "Singapore",
    value: "Singapore",
  },
  {
    id: 200,
    key: "Slovakia",
    value: "Slovakia",
  },
  {
    id: 201,
    key: "Slovenia",
    value: "Slovenia",
  },
  {
    id: 202,
    key: "Solomon Islands",
    value: "Solomon Islands",
  },
  {
    id: 203,
    key: "Somalia",
    value: "Somalia",
  },
  {
    id: 204,
    key: "South Africa",
    value: "South Africa",
  },
  {
    id: 205,
    key: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
  },
  {
    id: 206,
    key: "Spain",
    value: "Spain",
  },
  {
    id: 207,
    key: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    id: 208,
    key: "Sudan",
    value: "Sudan",
  },
  {
    id: 209,
    key: "Suriname",
    value: "Suriname",
  },
  {
    id: 210,
    key: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
  },
  {
    id: 211,
    key: "Swaziland",
    value: "Swaziland",
  },
  {
    id: 212,
    key: "Sweden",
    value: "Sweden",
  },
  {
    id: 213,
    key: "Switzerland",
    value: "Switzerland",
  },
  {
    id: 214,
    key: "Syrian Arab Republic",
    value: "Syrian Arab Republic",
  },
  {
    id: 215,
    key: "Taiwan",
    value: "Taiwan",
  },
  {
    id: 216,
    key: "Tajikistan",
    value: "Tajikistan",
  },
  {
    id: 217,
    key: "Tanzania (United Republic of)",
    value: "Tanzania (United Republic of)",
  },
  {
    id: 218,
    key: "Thailand",
    value: "Thailand",
  },
  {
    id: 219,
    key: "Timor-Leste",
    value: "Timor-Leste",
  },
  {
    id: 220,
    key: "Togo",
    value: "Togo",
  },
  {
    id: 221,
    key: "Tokelau",
    value: "Tokelau",
  },
  {
    id: 222,
    key: "Tonga",
    value: "Tonga",
  },
  {
    id: 223,
    key: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
  },
  {
    id: 224,
    key: "Tunisia",
    value: "Tunisia",
  },
  {
    id: 225,
    key: "Turkey",
    value: "Turkey",
  },
  {
    id: 226,
    key: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    id: 227,
    key: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
  },
  {
    id: 228,
    key: "Tuvalu",
    value: "Tuvalu",
  },
  {
    id: 229,
    key: "Uganda",
    value: "Uganda",
  },
  {
    id: 230,
    key: "Ukraine",
    value: "Ukraine",
  },
  {
    id: 231,
    key: "United Arab Emirates",
    value: "United Arab Emirates",
  },
  {
    id: 232,
    key: "United Kingdom",
    value: "United Kingdom",
  },

  {
    id: 233,
    key: "United States",
    value: "United States",
  },
  {
    id: 234,
    key: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  {
    id: 235,
    key: "Uruguay",
    value: "Uruguay",
  },

  {
    id: 236,
    key: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    id: 237,
    key: "Vanuatu",
    value: "Vanuatu",
  },
  {
    id: 238,
    key: "Venezuela",
    value: "Venezuela",
  },
  {
    id: 239,
    key: "Vietnam",
    value: "Vietnam",
  },
  {
    id: 240,
    key: "Virgin Islands (British)",
    value: "Virgin Islands (British)",
  },
  {
    id: 241,
    key: "Virgin Islands (U.S.)",
    value: "Virgin Islands (U.S.)",
  },
  {
    id: 242,
    key: "Wallis and Futuna",
    value: "Wallis and Futuna",
  },
  {
    id: 243,
    key: "Western Sahara",
    value: "Western Sahara",
  },
  {
    id: 244,
    key: "Yemen",
    value: "Yemen",
  },
  {
    id: 245,
    key: "Zambia",
    value: "Zambia",
  },
  {
    id: 246,
    key: "Zimbabwe",
    value: "Zimbabwe",
  },
];
