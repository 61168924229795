import React from "react";
import { Form } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";

function Select(props) {
  const { name, label, options, placeholder, required, countryApi, ...rest } =
    props;

  const [field, meta] = useField(name);
  const configTextfiled = {
    ...rest,
    ...field,
  };
  if (meta && meta.touched && meta.error) {
    configTextfiled.isValid = true;
    configTextfiled.isInvalid = meta.error;
  }
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className={`${required ? `required` : ``}`}>
          {label}
        </Form.Label>
        <Form.Select
          name={name}
          placeholder={placeholder}
          {...configTextfiled}
          {...rest}
        >
          {countryApi
            ? options.map((option) => {
                return (
                  <option className="opt" key={option._id} value={option.country_name}>
                    {option.country_name}
                  </option>
                );
              })
            : options.map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.key}
                  </option>
                );
              })}
        </Form.Select>
        <ErrorMessage
          name={name}
          className="invalid-feedback"
          component={"div"}
        />
      </Form.Group>
    </>
  );
}

export default Select;
