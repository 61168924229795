import React from "react";
import "./stadium.css";
import { Col, Container, Row } from "react-bootstrap";
import stadium from "../../../img/business/stadium/stadium.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Stadium(props) {
  return (
    <div className="stadium">
      <Container>
        <Row className="gy-5 mb-md-0 mb-3">
          <Col
            className="d-flex justify-content-lg-end align-items-center justify-content-md-center"
            lg={{ span: 6, order: 1 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className="leftContent  text-white">
              <p>
              {props.t("Biz stadium-section para")}
                
              </p>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-lg-start  justify-content-md-center"
            lg={{ span: 6, order: 2 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className="rightImg ps-2 ">
              <img src={stadium} alt="three person" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(Stadium));
