import { Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormLabel,
  Row,
} from "react-bootstrap";
import "./AccountDeleteForm.css";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";
// import marathon from "../../../img/home/talk/asset.png";
// import withRouter from "../../withRouter";
// import PropTypes from "prop-types";
// import { withTranslation } from "react-i18next";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import FormikController from "../../common/formui/FormikController";
// import Spinner from "react-bootstrap/Spinner";
// import apiClient from "../../../services/apiClient";
// import check from "../../../img/common/check.png";
// import {countriesList} from "../../../data/countriesList";

function AccountDeleteForm(props) {
  const [deleteFormEmail, setDeleteFormEmail] = useState("");
  const [deleteFormEmailWarning, setDeleteFormEmailWarning] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(deleteFormEmail);

    if (deleteFormEmail === "" || !isValidEmail) {
      setDeleteFormEmailWarning(true);
    } else {
      setDeleteFormEmailWarning(false);
      setDeleteFormEmail("");
      Store.addNotification({
        title: "Wonderful!",
        message: "Your form is submitted successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }
  };

  return (
    <div className="my-5 py-5">
      <Container>
        <Row className="align-items-center">
          <Col align="left" xl={6} lg={6}>
            <Card className="">
              <Card.Body>
                <Card.Text className="py-2">
                  <p>
                    We're sorry to see you go, but we understand that
                    circumstances change. Deleting your account is a permanent
                    decision, and we want to make sure you're fully aware of
                    what this entails.
                  </p>

                  <p>
                    By submitting your email, you are initiating the account
                    deletion process. Please note that your account will not be
                    deleted immediately. Once you submit your request, it will
                    be reviewed by our admin team for approval. After the
                    approval is granted, all of your data, including personal
                    information, account history, and preferences, will be
                    permanently removed from our system.
                  </p>
                  <p>
                    <b>Please note the following before you proceed:</b>
                  </p>
                  <ul>
                    <li>
                      You will lose access to all services associated with your
                      account.
                    </li>
                    <li>
                      All your personal data, stored preferences, and usage
                      history will be completely erased.
                    </li>
                    <li>
                      This action cannot be undone, and any information related
                      to your account will be irretrievable after the deletion
                      process is completed.
                    </li>
                  </ul>

                  <p className="mb-0">
                    We aim to ensure your data privacy and security at all
                    times. If you have any concerns before proceeding, please
                    feel free to reach out to our support team. 
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6} className="">
            <div className="">
            <h3 className="text-center mb-4">Account Delete Request</h3>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  subject: "",
                  message: "",
                  country: "Afghanistan",
                }}
              >
                <Form noValidate className="account-delete-form">
                  <Form.Control
                    control="input"
                    type="email"
                    label={"Form label Email"}
                    name="email"
                    placeholder={"Enter Your Email"}
                    value={deleteFormEmail}
                    onChange={(e) => setDeleteFormEmail(e.target.value)}
                  />
                  <div
                    className={`warning-message text-danger ${
                      deleteFormEmailWarning ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="my-1 mx-2 warning-message">{deleteFormEmail === "" ? "Email is required" : "Invalid Email"}</p>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      className="w-100 rounded-pill border-0 p-2 my-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Formik>
              {/* <div className="w-100 mt-5 rightColImg">
                <img src={marathon} className="w-100" alt="person runs" />
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AccountDeleteForm;
