import React from "react";
import "./b2playMiddleSection.css";
import people from "../../../img/home/b2PlayMiddleSection/Asset.png";
import logo from "../../../img/home/b2PlayMiddleSection/OriginalTransparent.png";
import { Card, Col, Container, Row } from "react-bootstrap";
import ball from "../../../img/home/b2PlayMiddleSection/VectorBall.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function B2playMiddleSection(props) {
  return (
    <div className="b2PlayMiddleSection position-relative">
      <div className="text-white position-absolute top-0 pt-5">
        <img src={ball} width={190} className="img-fluid" alt="ball" />
      </div>
      <Container>
        <Row>
          <Col
            lg={6}
            md={12}
            className="d-md-inline-flex flex-md-column align-items-md-center"
          >
            <div className="leftb2PlayMiddleSectionImgWrapper text-lg-start text-sm-center text-xs-center">
              <div className="mb-3 logoImg text-lg-start text-center">
                <img src={logo} alt="logo" />
              </div>
              <div className="peopleImg">
                <img src={people} alt="threepeople" />
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className="d-md-inline-flex justify-content-md-center mt-lg-0  mt-5 "
          >
            <Card className="b2PlayMiddleSectionCard">
              <Card.Body>
                <Card.Text className="py-2 b2PlayMiddleSectionCardDesc">
                  <p>
                    <span>B2play</span>
                    {props.t("Home middle-section para1")}
                  </p>

                  <p>{props.t("Home middle-section para2")}</p>

                  <p>{props.t("Home middle-section para3")}</p>

                  <p className="mb-0">{props.t("Home middle-section para4")}</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

B2playMiddleSection.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(B2playMiddleSection));
