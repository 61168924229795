import { useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

function CheckBoxes(props) {
  const { name, label, options, ...rest } = props;
  const [field] = useField(name);

  return options.map((option) => {
    return (
      <div key={option.key}>
        <Form.Check
          className="text-white mb-2"
          {...field}
          label={option.key}
          value={option.value}
          checked={field.value?.includes(option.value)}
          // inline
          {...rest}
        />
      </div>
    );
  });
}

export default CheckBoxes;
