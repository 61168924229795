import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import file from "../../../img/common/Vector.png";
import close from "../../../img/common/times.png";
import "./dropzone.css";

function Dropzone(props) {
  const {
    setFieldValue,
    placeImgesError,
    placeTeacherError,
    fromWorkShop,
    name,
    setFieldTouched,
    reset,
    maxFiles,
  } = props;

  const [myFiles, setMyFiles] = useState([]);
  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(() =>
      newFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setFieldValue(name, [...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: maxFiles !== 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length) {
        if (maxFiles === 1) {
          setMyFiles(() =>
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          setFieldValue(name, acceptedFiles);
        } else {
          setMyFiles((previousFiles) => [
            ...previousFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ]);
          setFieldValue(name, [...myFiles, ...acceptedFiles]);
        }
      }
    },
  });

  const thumbs = myFiles.map((file, index) => (
    <div className="thumb" key={index + 1}>
      <div className="thumbInner">
        <img
          src={file.preview}
          className="img"
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div className="removeIconCotainer">
          <img
            className="removeIconCotainerImage"
            src={close}
            alt={"removeIcon"}
            width={12}
            onClick={removeFile(file)}
          />
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    return () => myFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    reset && setMyFiles([]);
  }, [reset]);

  return (
    <section className="dropzone-section">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {/* <div className={`dropContainer ${fromWorkShop ? placeTeacherError !== undefined ? `validate` : `` : placeImgesError !== undefined ? `validate` : `` }`}> */}
        <div className={`dropContainer`}>
          <div>
            <img src={file} alt="fileupload" width={26} />
          </div>
          <p style={{ marginBottom: 0 }}>Drag and Drop here</p>
          <p className="fw-bold colorRed">or Browse</p>
        </div>
      </div>
      <aside className="thumbsContainer">{thumbs}</aside>
    </section>
  );
}

export default Dropzone;
