import React from "react";
import cycle from "../../../img/home/proposition/cycle.png";
import timer from "../../../img/home/proposition/timer.png";
import pay from "../../../img/home/proposition/pay.png";
import scanner from "../../../img/home/proposition/scanner.png";
import mobile from "../../../img/home/proposition/mobile.png";
import dollor from "../../../img/home/proposition/dollor.png";
import Time from "../../../img/home/proposition/Time.png";
import Varied from "../../../img/home/proposition/Varied.png";
import headphone from "../../../img/home/proposition/headphone.png";
import lang from "../../../img/home/proposition/lang.png";
import divider from "../../../img/home/proposition/divider.png";
import { Col, Container, Row } from "react-bootstrap";
import "./proposition.css";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Proposition(props) {
  return (
    <div className="proposition">
      <Container>
        <Row>
          <Col lg={12} className="text-center titile">
            <p style={{ marginBottom: 0 }} className="proposition-underline">{props.t("Home Proposition The Value Proposition")}</p>
            {/* <div className="horizontalLine" /> */}
          </Col>
          <Col
            lg={12}
            className="text-center mt-2"
            style={{ color: "#363636", fontSize: "16px" }}
          >
           {props.t("Home Proposition We are trying to create an sports eco-system")}
          </Col>
        </Row>
        <Row className="marginTop position-relative">
          <Col lg={12} className="d-flex justify-content-center">
            <div className="timeline  block mb-4 featuresCard">
              <h5 className="underline underline--left underline--center ms-3">
              {props.t("Home Proposition Features-section")}
              </h5>
              <div className="tl-item active">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={cycle} alt="cycle" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Features-section line1")}</div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={timer} alt="timer" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">
                  {props.t("Home Proposition Features-section line2")}
                  </div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-info">
                    <img src={pay} alt="pay" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">
                  {props.t("Home Proposition Features-section line3")}
                  </div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-info">
                    <img src={scanner} alt="scanner" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">
                  {props.t("Home Proposition Features-section line4")}
                  </div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={mobile} alt="mobile" width={15} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Features-section line5")}</div>
                </div>
              </div>
            </div>
            <div className="position-absolute divider">
              <img src={divider}  alt="divider"/>
            </div>
            <div className="timeline block mb-4 benefitsCard">
              <h5 className="underline underline--left underline--center ms-3">
              {props.t("Home Proposition Benefits-section")}
              </h5>
              <div className="tl-item active">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={mobile} alt="mobile" width={15} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">
                  {props.t("Home Proposition Benefits-section line1")}
                  </div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={dollor} alt="dollor" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Benefits-section line2")}</div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-info">
                    <img src={Time} alt="pay" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Benefits-section line3")}</div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-info">
                    <img src={Varied} alt="scanner" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Benefits-section line4")}</div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={headphone} alt="headphone" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Benefits-section line5")}</div>
                </div>
              </div>
              <div className="tl-item">
                <div className="tl-dot">
                  <span className="w-32 avatar circle gd-warning">
                    <img src={lang} alt="mobile" width={20} />
                  </span>
                </div>
                <div className="tl-content">
                  <div className="">{props.t("Home Proposition Benefits-section line6")}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Proposition.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Proposition));
