import React from "react";
import "./joinUs.css";
import classes from "../../../img/business/joinus/class.png";
import gym from "../../../img/business/joinus/gym.png";
import sport from "../../../img/business/joinus/sport.png";
import studio from "../../../img/business/joinus/studio.png";
import trainer from "../../../img/business/joinus/trainer.png";
import workshop from "../../../img/business/joinus/workshop.png";
import { Card, Col, Container, Row } from "react-bootstrap";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

function JoinUs(props) {

  const navigate =useNavigate();

  return (
    <div className="business-joinus">
      <Container>
        <Row>
          <Col sm={12}>
            <h1 className="business-joinus-underline fw-bold">
              {props.t("Biz joinus-section heading")}{" "}
            </h1>
            <h6
              className="text-center small fw-bold"
              style={{ color: "#020C53" }}
            >
              {props.t("Biz joinus-section subheading")}
            </h6>
          </Col>
          <Col
            sm={12}
            className="d-flex justify-content-center flex-wrap mb-lg-0 mb-md-5 mb-0"
          >
            <Card onClick={()=>{
              navigate({
                pathname : '/partner',
                search:createSearchParams({
                  form:'sportvenue'
                }).toString()
              })
            }}>
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img src={sport} alt="sport" className="w-100" />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz joinus-section card1")}{" "}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card onClick={()=>{
              navigate({
                pathname : '/partner',
                search:createSearchParams({
                  form:'gym'
                }).toString()
              })
            }}>
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img src={gym} alt="gym" className="w-100" />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz joinus-section card2")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card onClick={()=>{
              navigate({
                pathname : '/partner',
                search:createSearchParams({
                  form:'studio'
                }).toString()
              })
            }}>
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img src={studio} alt="studio" className="w-100" />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz joinus-section card3")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card onClick={()=>{
              navigate({
                pathname : '/partner',
                search:createSearchParams({
                  form:'trainer'
                }).toString()
              })
            }}>
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img src={trainer} alt="trainer" className="w-100" />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz joinus-section card4")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card onClick={()=>{
              navigate({
                pathname : '/partner',
                search:createSearchParams({
                  form:'workshop'
                }).toString()
              })
            }}>
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img src={classes} alt="classes" className="w-100" />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz joinus-section card5")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card onClick={()=>{
              navigate({
                pathname : '/partner',
                search:createSearchParams({
                  form:'workshop'
                }).toString()
              })
            }}>
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img src={workshop} alt="workshop" className="w-100" />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz joinus-section card6")}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(JoinUs));
