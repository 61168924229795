import React from "react";
import "./fact.css";
import { Col, Container, Row } from "react-bootstrap";
import manflag from "../../../img/business/fact/manflag.png";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
function facts(props) {
  return (
    <div className="business-facts">
      <Container>
        <Row>
          <Col align="center">
            <h1 className="business-facts-underline">
              {props.t("Biz fect-section heading")}
            </h1>
          </Col>
        </Row>
        <Row className="gy-2 mt-3 mb-3">
          <Col
            className="d-flex justify-content-lg-end justify-content-sm-center"
           
            lg={{ span: 6, order: 1 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className="leftContent  text-white">
              <p>
              {props.t("Biz fect-section para1")}
              </p>

              <p>
              {props.t("Biz fect-section para2")}
              </p>

              <p>
              {props.t("Biz fect-section para3")}
              </p>

              <p>{props.t("Biz fect-section para4")}</p>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-lg-start  justify-content-sm-center"
            lg={{ span: 6, order: 2 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className="rightImg">
              <img src={manflag} alt="manflag" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(facts));
