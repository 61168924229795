import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import mobile from "../../../img/home/solution/phone mockup_2 1.png";
import right from "../../../img/home/solution/right.png";
import vector from "../../../img/home/solution/Vector.png";
import "./solution.css";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Solution(props) {
  return (
    <Container fluid style={{ backgroundColor: "#FFCA3D" }}>
      <Container>
        <Row>
          <Col
            lg={6}
            className="pt-lg-5 pt-3 pb-lg-3 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center"
          >
            <div className="solution ">
              <div className="d-flex align-items-center ">
                <div className="solution-underline">
                  <img src={right} alt="right icon" />
                </div>
                <h1 className="solutionTitle ms-3 ">
                  {props.t("Home solution-section title")}
                </h1>
              </div>
              {/* <div style={{ border: "1px solid #020C53", width: "12%" }}></div> */}
              <div className="solutionDesc mt-3">
                <p>{props.t("Home solution-section para1")}</p>
                <p>{props.t("Home solution-section para2")}</p>
                <p>{props.t("Home solution-section para3")}</p>
                <p>{props.t("Home solution-section para4")}</p>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-lg-start  justify-content-md-center justify-content-sm-center position-relative"
          >
            <div className="solutionRightImage d-flex align-items-center justify-content-lg-start  justify-content-md-center justify-content-sm-center">
              <img src={mobile} alt="mobile image" />
            </div>

            <div className="position-absolute lampVector d-none d-lg-block d-xl-block">
              <img src={vector} alt="lamp image" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

Solution.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Solution));
