import React from "react";
import { Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./formsuccessmsg.css";
import success from "../../../img/common/VectorSuccess.png"
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function FormSuccessMsg(props) {
  return (
    <>
      <Container>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="formsuccessmsg"
        >
           {/* <Modal.Header closeButton>
            </Modal.Header> */}
          <Modal.Body className="text-center p-lg-4 p-2">
            <div className="imgDiv">
                <img src={success} alt="img" />
            </div>
            <h1>{props.t("Form success model title")}</h1>
            <p className="ps-md-5 pe-md-5 ps-3 pe-3">
            {props.t("Form success model desc")}
            </p>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}

FormSuccessMsg.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(FormSuccessMsg));
