import React from "react";
import "./joiningus.css";
import { Col, Container, Row } from "react-bootstrap";
import withRouter from "../../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function JoiningUs(props) {
  return (
    <div className="joining-us pt-5">
      <Container>
        <Row className="gy-3">
          <Col align="center">
            <div>
              <h1 className="joining-us-underline">
                {props.t("Partner joining-section heading")}
              </h1>
              <p className="text-white fst-italic mt-2 subtitle">
                {props.t("Partner joining-section sub-heading")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

JoiningUs.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(JoiningUs));
