import React from "react";
import JoiningUs from "./joiningus/JoiningUs";
import "./formscontainer.css";
import FormTab from "./formtab/FormTab";

function FormsContainer() {
  return (
    <div className="partner-forms">
      <JoiningUs />
      <FormTab />
    </div>
  );
}

export default FormsContainer;
