import React from "react";
import Navigation from "../navbar/Navigation";
import "./banner.css";
import { Col, Container, Row } from "react-bootstrap";
import DiscoverNewSide from "../discoverNewSide/DiscoverNewSide";
import BannerCard from "../bannerCard/BannerCard";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";


function Banner(props) {
  return (
    <>
      <div className="banner">
        <Navigation />
        <DiscoverNewSide />
      </div>
      <BannerCard />
    </>
  );
}

Banner.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Banner));
