import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import mobile from "../../img/banner/Free iPhone Xr 1.png";
import "./discoverNewSide.css";
import appleStore from "../../img/banner/appstore.png";
import googlestore from "../../img/banner/googleplay.png";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function DiscoverNewSide(props) {
  return (
    <>
      <div className="discoverNewSide">
        <Container>
          <Row>
            <Col lg={6} md={12} sm={12} className="discoverNewSideContent">
            {props.t("Home Banner Discover a")}
              <span>
                {props.t("Home Banner New")}
                <br />{props.t("Home Banner Side of Sports")}
              </span>
              <div className="appStore">
                
                <a href="https://apps.apple.com/app/b2play/id1601863913"><img src={appleStore} alt="applephone" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.genx.b2playappuser"><img src={googlestore} alt="googlephone" /></a>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="position-relative" >
              <div className="position-absolute twophone">
                <img src={mobile} alt="twophone" className=""  />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

DiscoverNewSide.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DiscoverNewSide));
