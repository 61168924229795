import React, { useState } from "react";
import "./businessProblem.css";
import timer from "../../../img/business/problem/timer.png";
import timerHov from "../../../img/business/problem/timerHov.png";
import softwareImg from "../../../img/business/problem/software.png";
import softwareHov from "../../../img/business/problem/softwareHov.png";
import paymentImg from "../../../img/business/problem/payment.png";
import paymentHov from "../../../img/business/problem/paymentHov.png";
import communicationImg from "../../../img/business/problem/communication.png";
import communicationHov from "../../../img/business/problem/communicationHov.png";
import handpaymentImg from "../../../img/business/problem/handpayment.png";
import handpaymentHov from "../../../img/business/problem/handpaymentHov.png";
import { Card, Col, Container, Row } from "react-bootstrap";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Problem(props) {
  const [time, setTime] = useState(false);
  const [software, setSoftware] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [handpayment, setHandpayment] = useState(false);
  const [payment, setPayment] = useState(false);

  return (
    <div className="business-problem">
      <Container>
        <Row className="gy-3">
          <Col sm={12}>
            <h1 className="business-problem-underline fw-bold">
              {props.t("Biz problem-section heading")}
            </h1>
          </Col>
          <Col className="d-flex justify-content-center flex-wrap mt-3 mb-md-5 mb-0">
            <Card
              onMouseOver={() => setTime(true)}
              onMouseOut={() => setTime(false)}
            >
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img
                    src={time ? timerHov : timer}
                    alt="timer"
                    className="w-100"
                  />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {" "}
                  {props.t("Biz problem-section card1")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              onMouseOver={() => setSoftware(true)}
              onMouseOut={() => setSoftware(false)}
            >
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img
                    src={software ? softwareHov : softwareImg}
                    alt="software"
                    className="w-100"
                  />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz problem-section card2")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              onMouseOver={() => setHandpayment(true)}
              onMouseOut={() => setHandpayment(false)}
            >
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img
                    src={handpayment ? handpaymentHov : handpaymentImg}
                    alt="payment"
                    className="w-100"
                  />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz problem-section card3")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              onMouseOver={() => setCommunication(true)}
              onMouseOut={() => setCommunication(false)}
            >
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img
                    src={communication ? communicationHov : communicationImg}
                    alt="communication"
                    className="w-100"
                  />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz problem-section card4")}
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              onMouseOver={() => setPayment(true)}
              onMouseOut={() => setPayment(false)}
            >
              <Card.Body className="text-center">
                <div className="cardImg mx-auto">
                  <img
                    src={payment ? paymentHov : paymentImg}
                    alt="handpayment"
                    className="w-100"
                  />
                </div>
              </Card.Body>
              <Card.Body className="text-center">
                <Card.Title className="fw-bold">
                  {props.t("Biz problem-section card5")}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(Problem));
