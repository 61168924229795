import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import home from "../../../img/partner/partnerfooter/home.png";
import clock from "../../../img/partner/partnerfooter/clock.png";
import comment from "../../../img/partner/partnerfooter/comment.png";
import envelope from "../../../img/partner/partnerfooter/envelope.png";
import "./partnerfooter.css";

function PartnerFooter() {
  return (
    <div className="partnerfooter">
      <Container>
        <Row className="gy-1">
          <Col  lg={{ span: 4, order: 1 }}  xs={{ span: 6, order: 1 }}>
            <div className="itemWrapper">
              <span className="imgDiv" id="homeImg">
                <img src={home} alt="home" />
              </span>
              <span className="ms-1">
                Budapest Budafoki út 183 Budapest, Hungary 1117
              </span>
            </div>
          </Col>
          <Col lg={{ span: 2, order: 2 }}   xs={{ span: 6, order:3 }}>
            <div className="itemWrapper">
              <span className="imgDiv">
                <img src={comment} alt="comment"  id="commentImg" />
              </span>
              <span className="ms-1">+36 (70) 403 2182</span>
            </div>
          </Col>
          <Col lg={{ span: 3, order: 3 }}  xs={{ span: 6, order:2 }}>
            <div className="itemWrapper">
              <span className="imgDiv" id="envelopeImg">
                <img src={envelope} alt="envelope" />
              </span>
              <span className="ms-1">info@b2playapp.com</span>
            </div>
          </Col>
          <Col lg={{ span: 3, order: 4 }}  xs={{ span: 6, order:4 }}>
            <div className="itemWrapper">
              <span className="imgDiv">
                <img src={clock} alt="clock"  id="clockImg" />
              </span>
              <span className="ms-1">Mon-Fri 10:00AM - 17:00PM</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PartnerFooter;
