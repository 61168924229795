import React from "react";
import "./b2playVideoSection.css";
import { Card, Col, Container, Row } from "react-bootstrap";
// import video from "../../../img/home/b2PlayVideoSection/Video.png";
import video from "../../../img/home/b2PlayVideoSection/B2play _User_Final.m4v";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function B2playVideoSection(props) {
  return (
    <div className="b2playVideoSection">
      <Container className="container">
        <Row>
          <Col align="center">
            <Card className="bg-transparent text-start border-0">
              <Card.Body>
                <Card.Title className="mb-2 titile">
                  {/* <p >B2Play Story</p> */}
                  {/* {props.t("Home Proposition Benefits-section line5")} */}
                  <div className="horizontalLine" />
                  <h1 className="b2playVideoSection-underline">
                    {props.t("Home video-section Story")}
                  </h1>
                </Card.Title>
                <Card.Text className="text-white mt-3">
                  <p>{props.t("Home video-section para1")}</p>
                  <p className="mb-0">{props.t("Home video-section para2")}</p>
                  <p>{props.t("Home video-section para3")}</p>
                  <div className="w-100 px-lg-5 mb-2">
                    <Video loop controls={["PlayPause"]} className="w-100" playsInline>
                      <source src={video} type="video/mp4" />
                    </Video>
                    {/* <img src={video} alt="video" className="w-100" /> */}
                  </div>
                  <p>{props.t("Home video-section para4")}</p>
                  <p>{props.t("Home video-section para5")}</p>
                  <p>{props.t("Home video-section para6")}</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

B2playVideoSection.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(B2playVideoSection));
